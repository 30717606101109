import { CategoryTreeType } from "pagesPage/stateInterfaces"
import { getPageSubSite } from "./../pagesPage/selectors";
import { ImageDisplayData } from "images/interfaces"
import { getFrontImageUrl } from "../front/staticServerRendering/frontImage";
import { checkIfInServerPreviewMode, isWcmEditor } from "config/serverConfig"
import { ImageSIzeDefintion } from "constants/imageSizes";
import { PixelEnviromentTypes } from "../marketing/pixels/stateInterface";



const apiPrefix = API_URL_BASE;
type favoritesActionType = "add" | "remove";

export const userPermissions = () => `${apiPrefix}/user/permissions`
export const favoritesGet = () => `${apiPrefix}/user/pagefavorites/get`
export const favoriteOn = () => `${apiPrefix}/favorite/add`
export const favoriteOff = () => `${apiPrefix}/favorite/remove`
export const promotionOn = () => `${apiPrefix}/article/promotion`
export const promotionOff = () => `${apiPrefix}/article/promotion`
export const favoritesAction = (pageId: string, action: favoritesActionType) => `${apiPrefix}/user/pagefavorites/${action}/${pageId}/`
export const searchTags = (searchTerm: string) => `${apiPrefix}/tag/search/${searchTerm}`
export const searchYoutubeTags = (searchTerm: string) => `${apiPrefix}/videos/videoUpload/search/${searchTerm}`
export const searchAuthors = (searchTerm: string) => `${apiPrefix}/tag/authors/search/${searchTerm}`
export const searchCategory = (searchTerm: string) => `${apiPrefix}/categories/search/${searchTerm}`
export const searchUser = (searchTerm: string) => `${apiPrefix}/user/search/${searchTerm}`
export const getCategoryChildren = (parentId: string) => `${apiPrefix}/categories/getchildren/${parentId}`
export const getChildCategories = (parentId: string) => `${apiPrefix}/categories/getcategoriesbyparent/${parentId}`
export const getCategoryAncestors = (categoryId: string) => `${apiPrefix}/categories/getancestors/${categoryId}`
export const getCategoryPathFromRoot = (categoryId: string) => `${apiPrefix}/categories/get_id_path_to_root/${categoryId}`
export const getCategoryDetails = (categoryId: string | number ) => `${apiPrefix}/categories/details/${categoryId}`
export const getInheritedDetails = (categoryId: string) => `${apiPrefix}/categories/details/inherited/${categoryId}`
export const saveCategoryChanges = (categoryId: string) => `${apiPrefix}/categories/save/${categoryId}`
export const deleteCategory = (categoryId: string) => `${apiPrefix}/categories/delete/${categoryId}`
export const getCategoryDefaults = (categoryId: string) => `${apiPrefix}/article/getCategoryDefaults/${categoryId}`
export const getCategoryDefaultsByName = (categoryName: string) => `${apiPrefix}/article/getCategoryDefaultsByStatsName/${categoryName}`
export const searchUserGroup = (searchTerm: string) => `${apiPrefix}/usergroups/search/${searchTerm}`
export const getArticlesData = () => `${apiPrefix}/article/get_list`
export const redirectLink = (type: string, pageId: string) => `${apiPrefix}/redirectLink/${type}/${pageId}/`
export const getSubsite = () => `${apiPrefix}/subsite/getsubsite`
export const getCategoryVodSeasons = (categoryId: string) => `${apiPrefix}/categories/vodSeasonList/${categoryId}`

export const duplicateArticleToCategory = () => `${apiPrefix}/article/duplicateArticleToCategory`
export const duplicateArticleParallelCategories = () => `${apiPrefix}/article/duplicateArticleParallelCategories`    
export const cloneArticle = (articleId: string) => `${apiPrefix}/article/duplicate/${articleId}`
export const searchArticles = (searchTerm: string) => `${apiPrefix}/article/search/${searchTerm}`
export const searchArticleById = (articleId: string) => `${apiPrefix}/article/searchById/${articleId}`
export const releaseArticleLock = (articleId: string) => `${apiPrefix}/article/release_lock/${articleId}`
export const getTalkbacksData = () => `${apiPrefix}/talkbacks/get_talkbacks`
export const getBlockedIpsData = () => `${apiPrefix}/talkbacks/blockedIps`
export const saveTalkbacks = () => `${apiPrefix}/talkbacks/save`
export const getTalkbacksWords = () => `${apiPrefix}/talkbacks/words`
export const getTalkbackAddWord = () => `${apiPrefix}/talkbacks/words/add`
export const getTalkbackRemoveWord = () => `${apiPrefix}/talkbacks/words/remove`
export const getTagsData = () => `${apiPrefix}/tag/get_list`
export const tagSave = (dontRiseErrorOnExists: boolean = false) => `${apiPrefix}/tag/save?${dontRiseErrorOnExists ? "dontRiseErrorOnExists" : ""}`
export const getTagMerge = () => `${apiPrefix}/tag/merge`
export const tagLinkToArticles = () => `${apiPrefix}/tag/link_articles`
export const getTagCreate = () => `${apiPrefix}/tags/addNew`
export const getTagName = (tagId: string) => `${apiPrefix}/tags/get_name_by_id/${tagId}`
export const getArticleName = (articleId: string) => `${apiPrefix}/article/get_name_by_id/${articleId}`
export const getCategoryName = (categoryId: string) => `${apiPrefix}/categories/get_name_by_id/${categoryId}`
export const getImagesData = () => `${apiPrefix}/image/get_list`
export const getVideosData = () => `${apiPrefix}/videos/get_list`
export const actionsOnArticles = (actionToDo: string) => `${apiPrefix}/article/${actionToDo}/`
export const previewPageData = (pageId: string) => `${apiPrefix}/page/savePreview/${pageId}/`
export const previewArticleData = (articleId: string) => `${apiPrefix}/article/savePreview/${articleId}/`
export const getShortCutLinks = () => `${apiPrefix}/user/shortCutLinks/get`
export const saveShortCutLinks = () => `${apiPrefix}/user/shortCutLinks/save`
export const getUserAvatar = (userId: string) => `${apiPrefix}/user/upload_profile_image`
export const getArticleDetails = (articleId: string) => `${apiPrefix}/article/getArticleDetails/${articleId}`
export const getAnalyticsParam = (param_name: string) => `${apiPrefix}/article/get_analytics_param/${param_name}`
export const getArticleHistory = (articleHistoryId: string) => `${apiPrefix}/article/get_version/${articleHistoryId}`
export const akumAndPopularityReport = () => `${apiPrefix}/analytics/videos/report`
export const getAllStamps = () => `${apiPrefix}/image/stamps/get_all`
export const getStampImage = (imageName:string) => `${apiPrefix}/image/stamps/get_image/${imageName}`
export const writersSalaryReport = () => `${apiPrefix}/analytics/writesSalary/report`

export const getImageUrl = (image: ImageDisplayData | string, resize: ImageSIzeDefintion) => {
    let url: string
    let didCrop = false;
    if (typeof (image) == "string") {
        url = image;
    } else {
        if (!isWcmEditor() && !checkIfInServerPreviewMode()) {
            return getFrontImageUrl(image, resize)
        }
        if (!image) {
            return "";
        }
        if (image.editedImageUrl) {
            return image.editedImageUrl;
        } else if (image.x === undefined || image.y === undefined || image.width === undefined || image.height === undefined) {
            url = image.url
        } else {
            if (!image.url) return ""
            didCrop = true;
            url = `${apiPrefix}/service/image/edit/?url=${image.url}`
            if (image.rotate) url += `&rotate=${image.rotate}`
            if (image.width && image.height) {
                const imageCopy = { ...image }
                if (imageCopy.x == null) imageCopy.x = 0;
                if (imageCopy.y == null) imageCopy.y = 0;
                url += `&crop=${imageCopy.x},${imageCopy.y},${Number(imageCopy.x) + Number(imageCopy.width)},${Number(imageCopy.y) + Number(imageCopy.height)}`
            }
            if (image.compositeType) {
                url += `&compositeType=${image.compositeType}`
            }
        }
    }
    if (url && resize && (resize.h || resize.w)) {
        if (!didCrop) url = `${apiPrefix}/service/image/edit/?url=${url}`
        url += `&resize=${resize.w || ""},${resize.h || ""}`
    }

    return url || "";
}

export const getImageAsLocalUrl = (imageUrl: string) => imageUrl

export const getSpecialTemplates = () => `${apiPrefix}/page/templates/getSpecialTemplates/`
export const getPageTemplatesDisplayDetails = (objecType: CategoryTreeType) => `${apiPrefix}/page/templates/getDisplayDetails/${objecType}`
export const getPageGrids = () => `${apiPrefix}/page/grids/getAll`
export const getPageNode = (pageId: string, timestamp: string) => `${apiPrefix}/page/${pageId}${timestamp ? `?last=${timestamp}` : ``}`
export const savePageChanges = (pageId: string) => `${apiPrefix}/page/save/${pageId}`
export const deletePage = (pageId: string) => `${apiPrefix}/page/delete/${pageId}`
export const publishPage = (pageId: string) => `${apiPrefix}/page/publish/${pageId}`
export const unpublishPage = (pageId: string) => `${apiPrefix}/page/unpublish/${pageId}`
export const cloningPage = (pageId: string) => `${apiPrefix}/page/clone/${pageId}`
export const sendPageSelectedBackgroundImage = () => `${apiPrefix}/page/updateBackgroundImage`
export const releaseMyLocksInPage = (pageId: string) => `${apiPrefix}/page/releaseMyLocks/${pageId}`
export const pageCloneCategory = () => `${apiPrefix}/page/pageCloneCategoryF`
export const lockPage = (pageId: string) => `${apiPrefix}/page/lock/${pageId}`
export const releasePageLock = (pageId: string) => `${apiPrefix}/page/releaseLock/${pageId}`


export const saveArticle = () => `${apiPrefix}/article/save`


export const getArticle = (articleId: string, lockArticle = true) => `${apiPrefix}/article/get_article/${articleId}${lockArticle ? `?locked=${lockArticle}` : ``}`
export const createSubCategory = (parentId: string) => `${apiPrefix}/categories/sub/add/${parentId}`

export const searchUsers = () => `${apiPrefix}/user/get_list`

export const getUser = (userId: string) => `${apiPrefix}/user/get/${userId}`
export const saveUser = () => `${apiPrefix}/user/save`
export const checkUser = (userName) => `${apiPrefix}/user/check_ad_valid/${userName}`

export const saveGroupsAndRoles = () => `${apiPrefix}/user/group/save_groups/`
export const getGroupsAndRoles = () => `${apiPrefix}/user/group/get_groups/`

export const uploadImages = () => `${apiPrefix}/image/upload`

export const createArchiveFolder = () => `${apiPrefix}/videos/videoUpload/createArchiveFolder`
export const deleteYoutubeVideo = (mediaVideoId: string) => `${apiPrefix}/videos/videoUpload/delete_youtube/${mediaVideoId}`
export const deleteVideo = (mediaVideoId: string) => `${apiPrefix}/videos/videoUpload/delete/${mediaVideoId}`
export const saveVideo = () => `${apiPrefix}/videos/videoUpload/save`
export const getVideos = (status: string) => `${apiPrefix}/videos/showVideos/${status ? status : "all"}`
export const getVideoDetails = () => `${apiPrefix}/videos/videoUpload/getDetails`
export const getProductionTypeOptions = () => `${apiPrefix}/videos/videoUpload/getProductionTypeOptions/`
export const saveEditedImage = (imageId: string) => `${apiPrefix}/image/update/${imageId}`
export const deleteEditedImage = (imageId: string) => `${apiPrefix}/image/remove/${imageId}`

export const logImageUse = (imageId: string) => `${apiPrefix}/image/logImageUse/${imageId}`
export const lockComponenta = (componentaId: string) => `${apiPrefix}/page/lockComponenta/${componentaId}`
export const releaseComponenta = (componentaId: string) => `${apiPrefix}/page/releaseComponenta/${componentaId}`
export const getComponentaExtraData = (componetaType: string) => `${apiPrefix}/page/getComponentaExtraData/${componetaType}`

export const getArticleActionsHistory = (articleId: string) => `${apiPrefix}/article/getActionHistory/${articleId}`
export const logVideoUse = (videoId: string) => `${apiPrefix}/videos/logVideoUse/${videoId}`

// pushes
export const loadPush = (pushId: string) => `${apiPrefix}/notification/loadPush/${pushId}`
export const getPushPackages = (restricted: boolean = false) => `${apiPrefix}/notification/loadPushPackages/${restricted ? "restricted" : ""}`
export const deletePush = (pushId: string) => `${apiPrefix}/notification/delete/${pushId}`
export const getPushSearchResults = () => `${apiPrefix}/notification/getPushSearchResults`
export const savePush = () => `${apiPrefix}/notification/save`

// files
export const uploadFiles = () => `${apiPrefix}/file/upload`
export const saveEditedFile = (fileId: string) => `${apiPrefix}/file/update/${fileId}`
export const deleteEditedFile = (fileId: string) => `${apiPrefix}/file/remove/${fileId}`
export const getFilesData = () => `${apiPrefix}/file/get_list`
export const uploadDocument = () => `${apiPrefix}/article/auto_article/convert_file_to_json/`
export const getS3PolicyData = () => `${apiPrefix}/videos/s3/get_policy`
export const checkS3Exist = () => `${apiPrefix}/videos/s3/exist`
export const sendS3Log = () => `${apiPrefix}/videos/s3/log`
export const uploadArticleAttachedFile = () => `${apiPrefix}/article/upload_attached_file/`
export const finishVideoUpload = () => `${apiPrefix}/ynethd_transcoder/encode_video`

// hot updates
export const createHotUpdatesGroup = () => `${apiPrefix}/hot_updates/save_group`
export const updateHotUpdatesGroup = () => `${apiPrefix}/hot_updates/save_group`
export const getHotUpdatesGroups = () => `${apiPrefix}/hot_updates/groups_list`
export const deleteHotUpdateGroup = (groupId: string) => `${apiPrefix}/hot_updates/remove_group/${groupId}`
export const createNewHotUpdateItem = () => `${apiPrefix}/hot_updates/save_item`
export const updateHotUpdatesItem = () => `${apiPrefix}/hot_updates/save_item`
export const getHotUpdatesItemsByGroup = (groupId: string) => `${apiPrefix}/hot_updates/items_list/${groupId}`
export const deleteHotUpdateItem = (itemId: string) => `${apiPrefix}/hot_updates/remove_item/${itemId}`
export const getGroupsForArticle = () => `${apiPrefix}/hot_updates/groups_list/items_exists`

// marketing - pixels
export const createNewPixelsGroup = () => `${apiPrefix}/marketing/pixels/saveGroup`
export const updatePixelsGroup = () => `${apiPrefix}/marketing/pixels/saveGroup`
export const createNewPixel = () => `${apiPrefix}/marketing/pixels/savePixel`
export const updateNewPixel = () => `${apiPrefix}/marketing/pixels/savePixel`
export const getPixelsPageData = (env: PixelEnviromentTypes = "desktop") => `${apiPrefix}/marketing/pixels/getPixelPage/${env}`
export const deletePixel = () => `${apiPrefix}/marketing/pixels/removePixel`
export const deletePixelsGroup = () => `${apiPrefix}/marketing/pixels/removeGroup`

// content sharing
export const isContentSharingSrcNameAvailable = (srcName: string) => `${apiPrefix}/page/isContentSharingSrcNameAvailable/${srcName}`
export const searchContentSharingSrcName = () => `${apiPrefix}/page/searchContentSharingSrcName`
export const getComponentaDataJson = (componentaId: string) => `${apiPrefix}/page/getComponentaDataJson/${componentaId}`

// calcalist
export const searchStocks = (text: string) => `${apiPrefix}/calcalist/stock/search_stock/${text}`

// google auto article
export const saveAutoArticleTable = () => `${apiPrefix}/article/automation/save`
export const loadAutoArticleTable = (article_id: string) => `${apiPrefix}/article/automation/load/${article_id}`
export const getColumnValue = (article_id: string, column_id: string, value_start_with: string) => `${apiPrefix}/article/automation/column_values/${article_id}/${column_id}/${value_start_with}`
export const getPushKeys = (article_id: string, column_id: string) => `${apiPrefix}/article/automation/column_values/${article_id}/${column_id}/`
export const sendAutoArticlePush = (article_id: string) => `${apiPrefix}/article/automation/push/${article_id}/send`
export const getLogsAutoArticlePush = (article_id: string) => `${apiPrefix}/article/automation/push/${article_id}/log`
export const getPushTypeKeys = (pushType: string, text?: string) => `${apiPrefix}/article/auto_article/${pushType}/${text ? text : ''}`
export const getEncodedMainKeys = (pushType: string) => `${apiPrefix}/article/auto_article/get_encoded_push_keys/${pushType}`
export const shortLoadAutoArticleTable = (article_id: string) => `${apiPrefix}/article/automation/short_load/${article_id}`

// real time
export const realTimeReport = () => `${apiPrefix}/statistics/realtimereport`
export const realTimeMinutesAgo = () => `${apiPrefix}/statistics/realtimeminutesago`

// related articles
export const videoRelatedArticles = (video_id: string) => `${apiPrefix}/linked_resources/get_articles/video/${video_id}`
export const imageRelatedArticles = (image_id: string) => `${apiPrefix}/linked_resources/get_articles/image/${image_id}`

// yokra
export const getYokraNewspaperByDate = (date: string) => `${apiPrefix}/newspaper/get_newspapers/${date}/`
export const saveYokraNewspaperPage = (pageId: number) => `${apiPrefix}/newspaper/save_supplement_page/${pageId}/`
export const saveYokraNespaperSupplement = (date: string) => `${apiPrefix}/newspaper/save_supplement/${date}/`
export const lockYokraPage = (pageId: number) => `${apiPrefix}/newspaper/lock_supplement_page/${pageId}/`
export const releaseYokraPage = (pageId: number) => `${apiPrefix}/newspaper/release_supplement_page/${pageId}/`
export const getSupplementPage = (pageId: number) => `${apiPrefix}/newspaper/get_supplement_page/${pageId}`
export const getRelatedArticles = (pageId: number) => `${apiPrefix}/newspaper/page/${pageId}/get_related_articles`
export const getYokraStatus = (date: string) => `/tasks/pdf/new_publish/${date}`
export const getYokraConfig6Json = (date: string) => `/newspaper/mrss/config/6/${date}/`
export const toggleYokraSuppelmentPagesEnable = () => `${apiPrefix}/newspaper/set_active_supplement/`
export const getNewspaperTypes = () => `${apiPrefix}/newspaper/get_newspaper_types`




// configFileEditor
export const saveSiteConfigFiles = () => `${apiPrefix}/site_config_files/save_version`
export const getSiteConfigFiles = () => `${apiPrefix}/site_config_files/get_versions/`

// General Params
export const getGPListByName = (gpName: string) => `${apiPrefix}/parameters/get_list/${gpName}`
export const saveGP = () => `${apiPrefix}/parameters/save`
export const deleteGP = (gpId: number) => `${apiPrefix}/parameters/delete/${gpId}`

// Article Reports
export const getArticleReports = (articleId: string, startDateFormatted: string, endDateFormatted: string) =>
`${apiPrefix}/statistics/article_report/?start_date=${startDateFormatted}&end_date=${endDateFormatted}&partial_url=${articleId}`

//Transcriptions
export const getTranscriptionS3PolicyData = () => `${apiPrefix}/transcriber/s3/get_policy`;
export const getTranscriptionApiUrl = () => `${apiPrefix}/transcriber/get_api_url`;
export const createTranscription = () => `${apiPrefix}/`;
