import { SiteItemData } from "../../../../interfaces";
import { VideoGalleryVerticalMutam } from "./videoGalleryVerticalMutam";
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";

export interface categoryObj {
    id: string
    categoryColor: string
    name:string
}

interface VideoGalleryVerticalMutamComponentaProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    firstAdPlace: number
    maxAds: number
    repeatAds: number
    shouldDisplayMobileAds: boolean
    category: categoryObj
    hideMoreButton:boolean 
    moreButtonText:string
    domain: string
    isTitleVisible: boolean
    isSubTitleVisible: boolean
    tabLink: string
    tabLinkTarget: LinkTargetProperties
}

export class CalcalistVideoGalleryVerticalMutamComponenta extends React.Component<VideoGalleryVerticalMutamComponentaProps, {}>{
    public static siteScriptName = "calcalistVideoGalleryVerticalMutamComponenta";

    public render() {
        const { videoList, hideMoreButton,moreButtonText,  isTabVisible, tabTitleText, repeatAds, maxAds, firstAdPlace, shouldDisplayMobileAds, category, domain, isTitleVisible, isSubTitleVisible, tabLink, tabLinkTarget } = this.props;

        return (
            <div className="calcalistVideoGalleryVerticalMutamComponenta">
                <VideoGalleryVerticalMutam
                    videoList={videoList}
                    isTabVisible={isTabVisible}
                    tabTitleText={tabTitleText}
                    repeatAds={repeatAds}
                    maxAds={maxAds}
                    firstAdPlace={firstAdPlace}
                    hideMoreButton={hideMoreButton}
                    moreButtonText={moreButtonText}
                    shouldDisplayMobileAds={shouldDisplayMobileAds}
                    category={category}
                    domain={domain}
                    isTitleVisible={isTitleVisible}
                    isSubTitleVisible={isSubTitleVisible}
                    tabLink={tabLink}
                    tabLinkTarget={tabLinkTarget}/>
            </div>
        )
    }
}
