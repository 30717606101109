import { SimpleLinkRenderer, HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { YnetPremiumLoginMobile } from "../ynetPremiumLogin/ynetPremiumLoginMobile"
import { yPlusHeaderMenuItem } from "../../../../../ynetPlusHeader/components/ynetPlusMobileHeader"
import { useState } from "react"
import { YplusSearchInput } from "../../../../../yPlusSubChannelHeader/components/searchInput"
import * as classNames from "classnames"
import * as map from "lodash/map"
import { YPlusHeaderNavItems } from "../premiumArticleTitleImageComponenta/premiumArticleTitleImageMutamComponenta"
import { YnetPlusUserMenuItems, YnetPianoLogin } from "../../../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin"

interface YplusMutamSideMenuProps {
    mainLogoLink: string
    parentSiteLink: string
    menuItems: YPlusHeaderNavItems[]
    categoryId: string
    searchPageLink: string
    isUserLoggedIn?: boolean
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
}

export const YplusMutamSideMenu = (props: YplusMutamSideMenuProps) => {
    const { mainLogoLink, parentSiteLink, menuItems, categoryId, searchPageLink, isUserLoggedIn,ynetPlusMenuItems } = props
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const openMenu = () => {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
        setIsOpened(true)
        analyticsMenuPushFunc()
    }

    const closeMenu = () => {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        setIsOpened(false)
        analyticsMenuPushFunc()
    }

    const analyticsMenuPushFunc = () => {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            const menuStatus = isOpened ? 'Close' : 'Open';
            window.dataLayer.push({
                event: 'Navigation_Events',
                Category: 'Ynet+ - Navigation',
                Action: `Side Menu - ${menuStatus}`
            });
        }
    }

    const clickAcessibilityBtn = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    const isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;
    const isPianoUserValid =  typeof window != "undefined" && window.tp && window.tp.pianoId &&  window.tp.pianoId.isUserValid();
    const isUserAuthenticated = isPianoLogin? isPianoUserValid : isUserLoggedIn;

    return (
        <div className="yplusMutamSideMenu">
            <div className="sideMenuBtn" onClick={openMenu}></div>

            <div className={classNames("sideMenu", { "opened": isOpened })}>
                <div className="sideMenuContent">
                    <div className="menuHeader">
                        <SimpleLinkRenderer className="withBgLogo" href={mainLogoLink} />
                        <div className="headerButtonsWrapper">
                            <button id="INDmenu-btn3" className="headerAccessibiltyBtn"
                                accessKey="m" aria-labelledby="INDbtnTooltip" onClick={clickAcessibilityBtn}>
                               
                            </button>
                            <button className="closeMenuBtn" onClick={closeMenu} />
                        </div>
                    </div>
                    {isPianoLogin?  <YnetPianoLogin isPremiumHeaderArticle={true} ynetPlusMenuItems={ynetPlusMenuItems} /> : <YnetPremiumLoginMobile />}
            
                    {searchPageLink !== undefined && (isUserAuthenticated || (typeof window !=="undefined" && window.AWS_PLUS_SEARCH_URL)) &&
                        <YplusSearchInput
                            className="mobileInput"
                            seachPageLink={searchPageLink}
                            isInputOpened={true}
                        />}

                    <ul className="YplusCategoriesList" >
                        {map(menuItems, (item, i) => {
                            return (
                                <li key={i} className={classNames("menuItem", { "selected": item.catId === categoryId })}>
                                    <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} >
                                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                    </HyperLinkBuilder>
                                </li>
                            )
                        })}
                    </ul>

                    <div className="contactLink" >
                        <div className="contact-row">
                            <a href="https://www.ynet.co.il/plus/contact" target="_blank" id="contactUs"> שירות לקוחות </a>
                        </div>
                        <div className="mini-contact-link">
                            <a href="//z.ynet.co.il/short/content/2018/privacypolicy/policy.html" target="_blank" id="privay">
                                {` מדיניות פרטיות | `}
                            </a>
                            <a href="//www.ynet.co.il/article/hklworybp" target="_blank" id="term">
                                {` תנאי שימוש | `}
                            </a>
                            <a href="https://www.ynet.co.il/plus/contact" target="_blank" id="contact">
                                {` צור קשר ` }
                            </a>
                        
                        </div>
                    </div>

                    <SimpleLinkRenderer className="mainSiteLogo" href={parentSiteLink} >
                        <div className="ynetLogo"></div>
                        <span>    ראשי</span>
                    </SimpleLinkRenderer>
                </div>

                <div className="closeDivArea" onClick={closeMenu} />
            </div>
        </div>
    )
}