import { SiteSimpleImage } from "../../../../commonComponents/inline/siteSimpleImage";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { BaseLinkTypes, LinkProperties, LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";
import * as classNames from "classnames"
import { isWcmEditor } from "config/serverConfig"
import { MynetSearchForm } from "./mobileHeader/myNetSearchInput";
const clickOutside = require('react-click-outside');

export interface SiteList {
    name: string
    link: string
    linkTarget: LinkTargetProperties
    url?: string
    subSite?: string
    mainSiteSubNav: SubNavItem[]
}
export interface SubNavItem {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    textColor: string
}
interface SiteMynetHeaderProps {
    mainSite: SiteList
    siteList: SiteList[]
    bgColor: string
    separatorColor: string
    changingFieldText: string
    changingFieldLink: LinkProperties<BaseLinkTypes>
    changingFieldLinkTarget: LinkTargetProperties
    changingFieldColor: string
    isShowMoreBtn?: boolean,
    moreBtnColor?: string
}
@clickOutside
export class SiteMynetHeaderComponenta extends React.Component<SiteMynetHeaderProps, { isMenuOpened: boolean }> {
    public static siteScriptName = "SiteMynetHeaderComponenta";
    constructor(props) {
        super(props)
        this.state = {
            isMenuOpened: false,

        }
    }

    handleClickOutside = () => {
        this.setState({ isMenuOpened: false })
    }
    private analyticsMynetAccessibilityPushFunc = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'accessibility',
                Category: 'Accessibility',
                Action: 'Accessibility Clicked'
            });
        }

    }

    private analyticsMynetRedMailPushFunc = () => {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'contact_us',
                Category: 'Contact Us',
                Action: 'red_mail'
            });
        }

    }

    private myNetDateFormatting = () => {
        const months = ["ינו'", "פבר'", "מרץ", "אפר'", "מאי", "יונ'", "יול'", "אוג'", "ספט'", "אוק'", "נוב'", "דצמ'"];
        let today = new Date()
        let formattedDate = today.getDate() + " " + months[today.getMonth()] + " " + today.getFullYear()
        return formattedDate;
    }
    private toggleMenu = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened })
    }
    render() {
        const { mainSite, siteList, bgColor, separatorColor, moreBtnColor, changingFieldText, changingFieldLink, changingFieldLinkTarget, changingFieldColor, isShowMoreBtn } = this.props
        const itemStyle = {
            borderLeft: `1px solid ${separatorColor}`
        }
        const englishItemStyle = {
            borderRight: `1px solid ${separatorColor}`
        }
        let itemsInMainNav = mainSite.mainSiteSubNav;
        let itemsInDropDown;
        const hasMoreButton = mainSite.mainSiteSubNav.length > 13 && isShowMoreBtn;

        if (hasMoreButton) {
            const lastElements = mainSite.mainSiteSubNav.length - 13;
            itemsInMainNav = mainSite.mainSiteSubNav.slice(0, 13);
            itemsInDropDown = mainSite.mainSiteSubNav.slice(-lastElements);
        }
        const isMenuHidden = !this.state.isMenuOpened ? "hidden" : ""
        return (
            <div className="MynetHeaderComponenta">
                <div className="mainHeader">
                    <div className="logo">
                        <HyperLinkBuilder href={mainSite.link} ariaLabel={mainSite.name} linkTarget={mainSite.linkTarget}>
                            <SiteSimpleImage src={mainSite.url} alt={mainSite.name} title={mainSite.name} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>
                    </div>
                    <div className="infoArea">
                        <a id="INDmenu-btn" onMouseUp={this.analyticsMynetAccessibilityPushFunc} className="follow" accessKey="m" aria-labelledby="INDbtnTooltip"
                            style={{ marginLeft: "10px", padding: "3px 5px", borderRadius: "50%", border: "1px solid #b11412", top: "0px", left: "unset", right: "unset" }}>
                            <img src="https://www.ynet.co.il/images/nagishot.png" style={{ width: "15px", height: "15px" }} />
                        </a>
                        <div className="dateToday">
                            {this.myNetDateFormatting()}
                        </div>

                        <div className="separator" />
                        <a onMouseUp={this.analyticsMynetRedMailPushFunc} href="mailto:mynet@yedtik.co.il" className="usefulLink redMail">
                            המייל האדום
                        </a>
                        <div className="separator" />
                        {changingFieldText && <HyperLinkBuilder className="usefulLink changingField" href={changingFieldLink.link.publishedLink}
                            style={{ color: changingFieldColor }} linkTarget={changingFieldLinkTarget}>
                            {changingFieldText}
                        </HyperLinkBuilder>}
                        {changingFieldText && <div className="separator" />}
                        <MynetSearchForm />
                    </div>

                </div>
                <div className="SubNavigationComponenta" style={{ background: bgColor }}>
                    <div className="slotsContent">
                        <div className="slotList">
                            {itemsInMainNav.map((slot, index) =>
                                <div className={classNames("slotView", { "inEditMode": isWcmEditor() })} style={itemStyle} key={index}>
                                    <HyperLinkBuilder href={slot.link} linkTarget={slot.linkTarget} style={{ color: slot.textColor }} >
                                        <span dangerouslySetInnerHTML={{ __html: slot.title }} ></span>
                                    </HyperLinkBuilder>
                                </div>
                            )}
                            {hasMoreButton && <div className={classNames("slotView secondLevelH", { "inEditMode": isWcmEditor() })} onClick={this.toggleMenu} style={{ color: moreBtnColor? moreBtnColor : "#fff" }} ><span >עוד
                        </span>
                                <span className="menuTogglerWrapper" onClick={this.toggleMenu}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="menuToggler" width="11.995" height="5.6" viewBox="0 0 11.995 5.6">
                                        <defs>
       
                                        </defs>
                                        <g id="drop_down" data-name="drop down" transform="translate(-151.032 -40.197)">
                                            <path id="Shape_744" d="M2510.03 1068.8a.842.842 0 0 1-.594-.243l-5.157-3.932a.831.831 0 0 1 0-1.181.845.845 0 0 1 1.188 0l4.564 3.34 4.563-3.34a.845.845 0 0 1 1.188 0 .832.832 0 0 1 0 1.181l-5.158 3.932a.837.837 0 0 1-.593.243zm0 0"  data-name="Shape 744" transform="translate(-2353 -1023)" fill={moreBtnColor? moreBtnColor : "#fff"} />
                                        </g>
                                    </svg>
                                </span>
                                <ul className={`secondLevelMenu ${isMenuHidden}`}>
                                    {itemsInDropDown.map((item, i) => {
                                        return (
                                            <li key={i} className={classNames("menuItem")}>
                                                <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} style={{ color: item.textColor }} >
                                                    <span dangerouslySetInnerHTML={{ __html: item.title }} ></span>
                                                </HyperLinkBuilder>
                                            </li>
                                        )
                                    }
                                    )}
                                </ul>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}


