import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { openWhatsAppShare, analyticsPushFunc } from "../../../../widgetsUtils"
import { FontSlider } from "../../../ynet1280/ynetHeader/components/mutamComponents/fontSliderComponent"
import { toggleSpotImPopUp } from "../../../article/componentas/articleSocialShare1280Component/articleSocialShareNew1280";
import { HeaderScrolledMenu } from "./headerScrolledMenu";

interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isFontMenuOpen: boolean
    fixed: boolean

}
interface HeaderProps {
    groupsWithItems: Group[]
    logoData: Logo
    ynetSearchPageLink: string
    linkToFb: string
    linkToInst: string
    isArticle: boolean
    layout?: string
    domain: string
    followUsText: string
}
export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}
export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}


export class SiteMagazineHeaderMutamComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteMagazineHeaderMutamComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isFontMenuOpen: false,
            fixed: false
        }
    }
    private toggleMenu = (isOpened:boolean) => {
        this.setState({ isOpened: isOpened })
    }
    //

    private stickyBar() {
        let element = document.getElementById('ArticleBodyComponent');
        const headerContainerPos = document.querySelector(".magazines-header").getBoundingClientRect().top;
        const headerPos = document.querySelector("#MagazineHeaderMutam").getBoundingClientRect().top;
        let isSticky = element ? headerContainerPos + 40 < 0 : headerPos + 40 < 0;

        if (isSticky) {
            this.setState({ isSticky: true });


        } else {
            this.setState({ isSticky: false })
        }

        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;


        if (element) {
            let headerElRect = document.querySelector(".ArticleHeaderMobileComponent") && document.querySelector(".ArticleHeaderMobileComponent").getBoundingClientRect();
            if (!headerElRect) {
                return
            }
            let scrolled = (winScroll / (element.offsetTop + element.offsetHeight - headerElRect.height)) * 100;
            if (scrolled < 100) {
                document.getElementById("progress-bar").style.width = scrolled + "%";

            } else {
                document.getElementById("progress-bar").style.width = "100%";
            }
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
        this.props.isArticle && this.setfontSize();
    }
    private setfontSize = () => {
        if (localStorage && localStorage.fontSize) {
            let size = localStorage.fontSize;
            document.documentElement.style.fontSize = size * parseInt(getComputedStyle(document.documentElement).fontSize) + "px";
        }

    }



    componentWillUnmount() {

        document.removeEventListener("scroll", this.throttledScrollHandler())

    }
    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private openShare = async () => {
        const { domain } = this.props;
        const current_url = `${window.location.href}?utm_source=${domain}&utm_medium=social&utm_campaign=general_share`;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }
    private handleCommentsPopupVisability = () => {
        if (typeof window !== "undefined") {
            if (window.enableSpotIm) {
                const commentsPopUp = document.querySelector('.spotimWrapper');
                if (commentsPopUp) {
                    toggleSpotImPopUp(commentsPopUp)
                }
            }
            else {
                window.handleCommentsPopupVisability && window.handleCommentsPopupVisability();
            }
        }
    }

    private showCommentsBtn = () => {
        return typeof window !== "undefined" && (window.handleCommentsPopupVisability || window.enableSpotIm);

    }
    private handleFontSizePopupVisability = () => {
        this.setState({ isFontMenuOpen: true })
    }
    private handleFontSizePopupClose = () => {
        this.setState({ isFontMenuOpen: false })
    }
    private clickAcessibilityBtn = () => {
        window[`EquallyAI`] && window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        const { groupsWithItems, logoData, followUsText, ynetSearchPageLink, linkToFb, linkToInst, isArticle, layout, domain } = this.props
        const { isSticky, isFontMenuOpen } = this.state
        const numOfComment = typeof window !== "undefined" && window.handleCommentsPopupVisability && window.wcmNumOfComments && window.wcmNumOfComments;
        const hidden = isSticky ? "hidden" : ""
        const tooltipHidden = !isSticky ? "hidden" : "";
        const url = typeof window !== "undefined" && isArticle && window.location.href;
        const isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;

        /*if (isArticle) {
            return <div className={classNames("MagazineHeaderMutam Article", { "notStickyHeader": !isSticky })} id="MagazineHeaderMutam">
                <div className="progress-container">
                    <div className="progress-bar" id="progress-bar"></div>
                </div>
                <a href={"javascript:history.back()"} className="goBack" title="back" />
                {!isSticky &&
                    <>
                        <div className={`logo`}>
                            <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                                <div className="darkModeLogo" aria-label="Page Logo" title="לוגו אתר" alt="לוגו אתר"></div>
                            </HyperLinkBuilder>

                        </div>
                        <button className="topBtns mobileShareBtn" onClick={this.openShare} title={"share"} />
                    </>}
                <div className={`btnTooltips ${tooltipHidden}`} >
                    <button className="topBtns fontSizeMenuOpen" onClick={this.handleFontSizePopupVisability}></button>
                    <button className="topBtns accessibiltyBtn" onClick={this.clickAcessibilityBtn} title="אתר נגיש" aria-labelledby="INDbtnTooltip" accessKey="m"></button>
                    <div className="topBtns whatsAppShareBtn" onClick={() => openWhatsAppShare(url, () => analyticsPushFunc("Whatsapp", "Article"), "Article Top")}></div>
                    <button className="topBtns mobileShareBtn" onClick={this.openShare} title={"share"} />
                    <button style={{ display: this.showCommentsBtn() ? "block" : "none" }} className="topBtns commentsOpen" id="commentButtonInHeader" onClick={this.handleCommentsPopupVisability}>{numOfComment}</button>

                </div>
                {isFontMenuOpen && <FontSlider onClose={this.handleFontSizePopupClose} lang="he" />}
            </div>
        } else
           */ return (
                <div className={classNames("MagazineHeaderMutam")} id="MagazineHeaderMutam" >
                    <div className="topAreaHeader">
                    <button className={`menuHamburger ${this.state.isOpened ? "openedMenu" : ""}`} onClick={()=>this.toggleMenu(!this.state.isOpened)} />
                   
                            <HyperLinkBuilder className={`logo`} href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                                <img  aria-label="Page Logo" title={logoData.mediaTitle} alt={logoData.mediaTitle} src={logoData.url} />
                            </HyperLinkBuilder>


                    </div>

               <HeaderScrolledMenu
                        groupsWithItems={groupsWithItems}
                        isVisible={this.state.isOpened}
                        ynetSearchPageLink={ynetSearchPageLink}
                        linkToFb={linkToFb}
                        linkToInst={linkToInst}
                        followUsText={followUsText}
                        layout={layout}
                        domain={domain}
                    />
                </div>

            )

    }
}