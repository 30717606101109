import { ExtededImageDetails } from "../../../../../images/interfaces"
import { ArticleShareButtons } from "../../../../siteWidgets/socialShareButtons/ArticleShareButtons/ArticleShareButtons"
import * as apiUrls from "config/apiUrls"
import { ImageSize } from "constants/imageSizes"

interface GalleryProps {
    currentImageIndex: number
    images: ExtededImageDetails[]
    close: () => void
    swipeLeft: () => void
    swipeRight: () => void
    isLtr: boolean
}

export class ArticleGalleryView extends React.Component<GalleryProps, {}>{

    private popUpCloseButton: HTMLButtonElement;
    private focusableEls;

    componentDidMount() {
        this.popUpCloseButton.focus()
        this.focusableEls = document.getElementById('images_gallery').querySelectorAll('input:not([disabled]), a[href], area[href], button:not([disabled]), [tabindex="0"], iframe[src]');
        document.addEventListener("keydown", this.handleKeyPress)
    }

    componentDidUpdate(_prevProps: GalleryProps) {
        if (_prevProps.currentImageIndex !== this.props.currentImageIndex) {
            this.popUpCloseButton.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress)
    }


    private handleKeyPress = (e: any): void => {
        if (e.key === "Escape") { this.props.close() }
        if (e.key === "ArrowLeft") { this.props.swipeLeft() }
        if (e.key === "ArrowRight") { this.props.swipeRight() }
        if (e.keyCode === 9) {
            if (this.focusableEls.length > 1) {
                if (e.shiftKey) {
                    this.handleBackwardTab(e, this.focusableEls);
                } else {
                    this.handleForwardTab(e, this.focusableEls);
                }
            }
            else e.preventDefault();
        }
    }

    private handleBackwardTab = (e, focusableEls) => {
        if (document.activeElement === focusableEls[0]) {
            e.preventDefault();
            focusableEls[focusableEls.length - 1].focus();
        }
    }

    private handleForwardTab = (e, focusableEls) => {
        if (document.activeElement === focusableEls[focusableEls.length - 1]) {
            e.preventDefault();
            focusableEls[0].focus()
        }
    }

    render() {
        const { currentImageIndex, images, swipeLeft, close, swipeRight, isLtr } = this.props
        const numOfImages = images.length
        let ltrClassName = isLtr ? "englishSite" : ""
        return (
            <div key={"SiteArticleImagesGallery"} role="dialog"
                aria-label="images gallery"
                id="images_gallery" className="SiteArticleImagesGallery"
                onClick={close} tabIndex={0}>
                <div className="GalleryWrapper" onClick={e => e.stopPropagation()}>
                    <div className={`closeBtnWrapper ${ltrClassName}`}><button className="closeBtn yicon-x"
                        title={__("Close")} aria-label={__("Close")} onClick={close}
                        ref={ref => this.popUpCloseButton = ref}
                    /></div>
                    <button className={`navigationArrow ${"swipeLeft"}`} title="prev" aria-label="prev" onClick={swipeLeft} />
                    <div className="imageContainer" role="image" aria-label={images[currentImageIndex].alt} style={{ backgroundImage: `url(${apiUrls.getImageUrl(images[currentImageIndex], { imageSize: ImageSize.xLarge })})` }} />

                    <button className={`navigationArrow ${"swipeRight"}`} title="next" aria-label="next" onClick={swipeRight} />
                    <div className={`imageInfoAndShareOptions ${ltrClassName}`}>
                        <div className="imageInfo">
                            {images[currentImageIndex] && images[currentImageIndex].caption && <div className="caption">{images[currentImageIndex].caption}</div>}
                            {images[currentImageIndex] && images[currentImageIndex].credit && images[currentImageIndex].credit !== " " && <div className="credit"><span>(</span><span>{images[currentImageIndex].credit})</span></div>}
                        </div>
                        <div className="shareOptions">
                            <ArticleShareButtons className={"ArticleImageGalleryShareButtons"} lang={isLtr ? "en" : "he"} />
                        </div>
                    </div>

                    {numOfImages > 1 && <div className={`imagesCounter`}>
                        <button className={`navigationArrow ${"swipeLeft"}`} title="prev" aria-label="prev" onClick={swipeLeft} />

                        <span className="current">{currentImageIndex + 1 < 10 ? `0${currentImageIndex + 1}` : currentImageIndex + 1}</span>
                        <span className="totalNumOfImages">/{numOfImages < 10 ? `0${numOfImages}` : numOfImages}</span>
                        <button className={`navigationArrow ${"swipeRight"}`} title="next" aria-label="next" onClick={swipeRight} />
                    </div>}

                </div>
            </div>
        )
    }
}

