
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SiteLayoutType } from '../../../../../pagesPage/stateInterfaces';
import { ArticleImagesFullScreenGallery } from '../articleImagesFullScreenGallery/articleImagesFullScreenGallery';
import { ArticleImageGallery } from "./articleImageGalleryComponenta"


interface ArticleImageSliderGalleryComponentaProps {
  images: ArticleImageGallery[]
  articleId: string
  siteLayoutType: SiteLayoutType
  mainOptions: any
  componentaId?: string
  thumbOptions: any
  imagesJson?: string
}

interface ArticleImageSliderGalleryComponentaState {
  currentIndex: number
  showFullScreenGallery: boolean
}

export default class ArticleImageSliderGalleryComponenta extends React.Component<ArticleImageSliderGalleryComponentaProps, ArticleImageSliderGalleryComponentaState> {

  constructor(props: ArticleImageSliderGalleryComponentaProps) {
    super(props);
    this.state = {
      currentIndex: 0,
      showFullScreenGallery: false
    };
  }

  public static siteScriptName = "ArticleImageSliderGalleryComponenta";
  private mainRef;
  private thumbsRef;
  private translateX = 0;
  private sliderRange;

  componentDidMount(): void {
    if (this.mainRef && this.thumbsRef && this.thumbsRef.splide) {
      this.mainRef.sync(this.thumbsRef.splide);
    }
  }

  renderSlides = (isMainImage) => {
    return this.props.images.map((item, index) => {
      const { componentaId, siteLayoutType } = this.props;
      return <SplideSlide key={item.imageId}>
        {isMainImage && siteLayoutType === 'mobileApp' ?
          (<a data-href="image_gallery" href={`image_gallery?componentaId=${componentaId}&index=${index}&imageId=${item.imageId}&type=.jpg`}>
            <img src={item.imageSrc} alt={item.caption} />
          </a>)
          : <img src={item.imageSrc} alt={item.caption} />}
      </SplideSlide>
    })
  }

  handleSliderMove = (splideObj, index: number) => {
    this.setState({
      currentIndex: index,
    });
  }


  handleOpenGallery = () => {
    this.setState({ showFullScreenGallery: true })
    document.body.style.overflow = "hidden";
  }

  handleCloseGallery = (index) => {
    this.setState({ showFullScreenGallery: false, currentIndex: index }, () => {
      this.thumbsRef.splide.Components.Controller.go(index)
    })
    document.body.style.overflow = "auto";
  }


  render() {

    const { images, mainOptions, thumbOptions, componentaId, siteLayoutType, imagesJson, articleId } = this.props;
    const { currentIndex, showFullScreenGallery } = this.state;

    return (
      <div className="ArticleImageSliderGalleryComponenta">
        <ArticleImagesFullScreenGallery
          open={this.handleOpenGallery}
          close={this.handleCloseGallery}
          show={showFullScreenGallery}
          siteLayoutType={siteLayoutType}
          index={currentIndex} isLtr={false}
          images={images} articleId={articleId}
        />
        {siteLayoutType === 'mobileApp' && < script type="text/javascript" dangerouslySetInnerHTML={{ __html: `const galleryImagesArrayObjects_${componentaId} = ${imagesJson}` }} />}
        <div className="main-gallery">
          {siteLayoutType === 'mobileApp' ? (<a data-href="image_gallery" href={`image_gallery?componentaId=${componentaId}&index=${currentIndex}&imageId=${images[currentIndex].imageId}&type=.jpg`}>
            <div className="fullscreen-gallery-icon-pf"></div></a>)
            : <div onClick={this.handleOpenGallery} className="fullscreen-gallery-icon-pf"></div>
          }
          <Splide
            onClick={this.handleOpenGallery}
            onMove={this.handleSliderMove}
            options={mainOptions}
            className="main-slider"
            ref={ref => this.mainRef = ref}
          >
            {this.renderSlides(true)}
          </Splide>
          <div className="caption-credit">{`${images[currentIndex].caption} (${images[currentIndex].credit})`}</div>
        </div>

        <Splide
          className="thumbnail-slider"
          options={thumbOptions}
          ref={ref => this.thumbsRef = ref}
          onMove={this.handleSliderMove}
        >
          {this.renderSlides(false)}
        </Splide>
        <div className="article-carousel-scroller-navigation">
          <input 
            type="range" 
            min="1" 
            max={this.props.images.length} 
            value={`${currentIndex + 1}`}
            className="slider" 
            id="sliderRange" 
            ref={ref => this.sliderRange = ref} 
            onChange={(e:any) => {
              const index = parseInt(e.target.value, 10) - 1;
              this.mainRef.splide.go(index);
              this.handleSliderMove(this.mainRef.splide, index);
            }}
            style={{ '--slider-width': `${740 / this.props.images.length }px` } as React.CSSProperties}
          />
        </div>
      </div>
    );
  }
}