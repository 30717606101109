import Slider from "react-slick"
import { SiteItemData } from "../../../../interfaces";
import { PromoCarousel } from "../../../ynet1280/videoGalleryVerticalWidget/components/promoCarousel";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { VideoGalleryVerticalItem } from "./videoGalleryVerticalItem";
import { VideoVerticalFeedItem } from "./videoVerticalFeedItem";
import { categoryObj } from "./calcalistVideoGalleryVerticalComponenta";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";

interface VideoGalleryVerticalDesktopProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    category: categoryObj
    domain: string
    isTitleVisible: boolean
    isSubTitleVisible: boolean
}

interface VideoGalleryVerticalDesktopState {
    feedActiveSlide: number
    isFeedDisplay: boolean
    initialFeedSlide: number
    togglePromoActive: boolean
    currentPromoIndex: number
}

export class VideoGalleryVerticalDesktop extends React.Component<VideoGalleryVerticalDesktopProps, VideoGalleryVerticalDesktopState>{
    constructor(props) {
        super(props);
        this.state = {
            feedActiveSlide: 0,
            isFeedDisplay: false,
            initialFeedSlide: 0,
            togglePromoActive: false,
            currentPromoIndex: 0
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    private slider;

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }


    private onOpenVideoGalleryView = (videoIndex: number) => {
        const { videoList } = this.props;
        const initialFeedSlide = this.getListWithAds().findIndex((element) => element.itemId === videoList[videoIndex].itemId);
        this.setState({ initialFeedSlide: initialFeedSlide, feedActiveSlide: initialFeedSlide }, () => { this.setState({ isFeedDisplay: true }) });
        disablePageRefresh();
    }

    private onCloseVideoGalleryView = () => {
        this.setState({ isFeedDisplay: false });
        enablePageRefresh();
    }

    private onPromoMouseHover = (currentPromoIndex: number) => {
        this.setState({ currentPromoIndex: currentPromoIndex });
    }

    private handleKeyPress(e) {
        if (e.keyCode === 27) {
            this.onCloseVideoGalleryView();
        }
    }

    private onNextSlide = () => {
        this.slider.slickPrev();
    }

    private getListWithAds = () => {
        const { repeatAds, maxAds, firstAdPlace, videoList } = this.props;
        const videoListCopy = [...videoList];
        const videoListLength = videoListCopy.length;
        let numberOfAds = firstAdPlace > videoListLength ? 0 : Math.min(maxAds, videoListLength - firstAdPlace, Math.floor((videoListLength - firstAdPlace) / repeatAds + 1));
        const newListLength = videoListLength + numberOfAds;
        const baseId = "ads.vertical.native.";
        let newList = [];
        let adCount = 1;

        if (maxAds === 0) return videoListCopy;

        for (let i = 0; i < newListLength; i++) {
            if (i < firstAdPlace) {
                newList.push(videoListCopy.shift());
            } else if (i === firstAdPlace && numberOfAds > 0) {
                newList.push(`${baseId}${adCount}`);
                numberOfAds--;
                adCount++;
            } else if (numberOfAds > 0 && (i - firstAdPlace) % (repeatAds + 1) === 0) {
                newList.push(`${baseId}${adCount}`);
                numberOfAds--;
                adCount++;
            } else {
                newList.push(videoListCopy.shift());
            }
        }

        return newList;
    }

    public render() {
        const { videoList, isTabVisible, tabTitleText, domain, tabLink, tabLinkTarget, isTitleVisible, isSubTitleVisible } = this.props;
        const { isFeedDisplay, initialFeedSlide, feedActiveSlide, togglePromoActive, currentPromoIndex } = this.state;

        const feedDisplaySettings = {
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: initialFeedSlide, 
            draggable: false,
            rtl: true,
            beforeChange: (current, next) => this.setState({ feedActiveSlide: Math.abs(next - this.getListWithAds().length + 1) }),
            nextArrow: <SampleNextArrow feedActiveSlide={feedActiveSlide} listLength={this.getListWithAds().length} />,
            prevArrow: <SamplePrevArrow feedActiveSlide={feedActiveSlide} listLength={this.getListWithAds().length} />,
        };

        return (
            <div className="videoGalleryVerticalDesktopWrapper">
                {isTabVisible && <div className="videoGalleryVerticalTab componentaTab">
                    <HyperLinkBuilder
                        className="videoLink"
                        href={tabLink}
                        linkTarget={tabLinkTarget}
                    >
                        {tabTitleText}
                    </HyperLinkBuilder>
                </div>}
                {isFeedDisplay && <div className="closeBtn" onClick={this.onCloseVideoGalleryView}></div>}
                <PromoCarousel
                    imageWidth={176}
                    width={890}
                    itemListLength={videoList.length}
                    marginRight={16}
                    togglePromoActive={togglePromoActive}
                    currentPromoIndex={currentPromoIndex}
                    videoList={videoList}
                    initialRightOffset={16}
                >
                    {videoList.map((videoItem, index) => {
                        return (
                            <VideoGalleryVerticalItem
                                videoItem={videoItem}
                                onOpenVideoGalleryView={this.onOpenVideoGalleryView}
                                videoIndex={index}
                                key={videoItem.itemId}
                                onPromoMouseHover={this.onPromoMouseHover}
                                isTitleVisible={isTitleVisible}
                            />
                        )
                    })}
                </PromoCarousel >
                {isFeedDisplay &&
                    <div className="feedDisplay">
                        <Slider {...feedDisplaySettings} className="slider" ref={slider => (this.slider = slider)} >
                            {this.getListWithAds().map((videoItem, index) => {
                                if ((typeof videoItem) === 'string') {
                                return (<div key={index} id={videoItem} className="ads_vertical_video"></div>)
                                } else {
                                return ( <VideoVerticalFeedItem
                                        videoItem={videoItem}
                                        key={videoItem.itemId}
                                        feedActiveSlide={feedActiveSlide}
                                        onNextSlide={this.onNextSlide}
                                        onCloseVideoGalleryView={this.onCloseVideoGalleryView}
                                        domain={domain}
                                        index={index}
                                        isTitleVisible={isTitleVisible} 
                                        isSubTitleVisible={isSubTitleVisible}/>
                                  )
                                }
                            })}
                        </Slider>
                    </div>
                }
            </div>
        )
    }
}

const SampleNextArrow = (props) => {
    const { onClick, feedActiveSlide } = props;
    const shouldDisplayArrow = feedActiveSlide !== 0;
    const handleClick = () => {
        onClick();
    }

    return (
        <button
            className={`slickArrow slickNext ${shouldDisplayArrow ? "" : "slickDisabled"}`}
            onClick={handleClick}
        ></button>
    );
}

const SamplePrevArrow = (props) => {
    const { onClick, feedActiveSlide, listLength } = props;
    const shouldDisplayArrow = (feedActiveSlide + 1) !== listLength;
    const handleClick = () => {
        onClick();
    }
    return (
        <button
            className={`slickArrow slickPrev ${shouldDisplayArrow ? "" : "slickDisabled"}`}
            onClick={handleClick}
        ></button>
    );
}