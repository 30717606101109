import { VideoGalleryVerticalItem } from "./videoGalleryVerticalItem";
import Slider from "react-slick"
import { SiteItemData } from "../../../../interfaces";
import { VideoVerticalFeedItem } from "./videoVerticalFeedItem";
import { PromoCarousel } from "./promoCarousel";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { categoryObj } from "./videoGalleryVerticalComponenta";

interface VideoGalleryVerticalDesktopProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    category: categoryObj
    domain: string
    showPrerollAds: boolean
}

interface VideoGalleryVerticalDesktopState {
    feedActiveSlide: number
    isFeedDisplay: boolean
    initialFeedSlide: number
    togglePromoActive: boolean
    currentPromoIndex: number
}

export class VideoGalleryVerticalDesktop extends React.Component<VideoGalleryVerticalDesktopProps, VideoGalleryVerticalDesktopState> {
    public static siteScriptName = "VideoGalleryVerticalDesktop";
    constructor(props) {
        super(props);
        this.state = {
            feedActiveSlide: 0,
            isFeedDisplay: false,
            initialFeedSlide: 0,
            togglePromoActive: false,
            currentPromoIndex: 0
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    private slider;

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }


    private onOpenVideoGalleryView = (originalVideoListIndex: number) => {
        const { videoList } = this.props;
        const initialFeedSlide = this.getListWithAds().findIndex((element) => element.itemId === videoList[originalVideoListIndex].itemId);
        this.setState({ initialFeedSlide: initialFeedSlide, feedActiveSlide: initialFeedSlide }, () => { this.setState({ isFeedDisplay: true }) });
        disablePageRefresh();
    }

    private onCloseVideoGalleryView = () => {
        this.setState({ isFeedDisplay: false });
        enablePageRefresh();
    }

    private onPromoMouseHover = (currentPromoIndex: number) => {
        this.setState({ currentPromoIndex: currentPromoIndex });
    }

    private handleKeyPress(e) {
        if (e.keyCode === 27) {
            this.onCloseVideoGalleryView();
        }
    }

    private onNextSlide = () => {
        this.slider.slickPrev();
        this.pushGA4Event("swipe_left", true);
    }

    private pushGA4Event = (action: string, isFeedDisplay: boolean) => {
        if (typeof window == "undefined" || !window.dataLayer) return;
        const { category } = this.props;
        const actionPrefix = category && category.name ? (category.name === "דף הבית") ? "homepage" : category.name : "homepage";
        const gaAction = isFeedDisplay ? `video_${action}` : `${actionPrefix}_${action}`;

        window.dataLayer.push({
            event: "content_click",
            vertical_video_action: gaAction,
        });
    };


    private getListWithAds = () => {
        const { repeatAds, maxAds, firstAdPlace, videoList, showPrerollAds } = this.props;
        const videoListCopy = [...videoList];
        const videoListLength = videoListCopy.length;
        let numberOfAds = firstAdPlace > videoListLength ? 0 : Math.min(maxAds, videoListLength - firstAdPlace, Math.floor((videoListLength - firstAdPlace) / repeatAds + 1));
        const newListLength = videoListLength + numberOfAds;
        const baseId = "ads.vertical.native.";
        let newList = [];
        let adCount = 1;

        if (showPrerollAds) return this.getListWithPreRollsAds();
        if (maxAds === 0) return videoListCopy;

        for (let i = 0; i < newListLength; i++) {
            if (i < firstAdPlace) {
                newList.push(videoListCopy.shift());
            } else if (i === firstAdPlace && numberOfAds > 0) {
                newList.push(`${baseId}${adCount}`);
                numberOfAds--;
                adCount++;
            } else if (numberOfAds > 0 && (i - firstAdPlace) % (repeatAds + 1) === 0) {
                newList.push(`${baseId}${adCount}`);
                numberOfAds--;
                adCount++;
            } else {
                newList.push(videoListCopy.shift());
            }
        }

        return newList;
    }

    private getListWithPreRollsAds = () => {
        const { repeatAds, maxAds, firstAdPlace, videoList, showPrerollAds } = this.props;
        const videoListCopy = [...videoList];
        const videoListLength = videoListCopy.length;
        let numberOfAdsLeft = firstAdPlace > videoListLength ? 0 : Math.min(maxAds, videoListLength - firstAdPlace, Math.floor((videoListLength - firstAdPlace) / repeatAds + 1));
        const totalAdsNumber = firstAdPlace > videoListLength ? 0 : Math.min(maxAds, videoListLength - firstAdPlace, Math.floor((videoListLength - firstAdPlace) / repeatAds + 1));
        
        if (maxAds === 0) return videoListCopy;
        let newList = [];
        
        for (let i = 0; i < videoList.length; i++) {
            let prerollIndex = Math.abs(numberOfAdsLeft - (totalAdsNumber + 1));
            if (i + 1 < firstAdPlace) {
                newList.push({ ...videoList[i], hidePrerollAds: true, prerollIndex: undefined });
            } else if (i === firstAdPlace && numberOfAdsLeft > 0) {
                newList.push({ ...videoList[i], hidePrerollAds: false, prerollIndex: showPrerollAds ? prerollIndex : undefined });
                numberOfAdsLeft--;
            } else if (numberOfAdsLeft > 0 && (i - firstAdPlace) % (repeatAds + 1) === 0) {
                newList.push({ ...videoList[i], hidePrerollAds: false, prerollIndex: showPrerollAds ? prerollIndex : undefined });
                numberOfAdsLeft--;
            } else {
                newList.push({ ...videoList[i], hidePrerollAds: true, prerollIndex: undefined });
            }
        }

        return newList;
    }

    private onFeedArrowClick = () => {
        const activeVideoElement = document.querySelector(".videoGalleryVerticalComponenta .feedDisplay .slick-active .videoVerticalFeedItem #player .fp-engine") as HTMLVideoElement;
        if (activeVideoElement) activeVideoElement.pause();
    }

    public render() {
        const { videoList, isTabVisible, tabTitleText, domain, showPrerollAds } = this.props;
        const { isFeedDisplay, initialFeedSlide, feedActiveSlide, togglePromoActive, currentPromoIndex } = this.state;

        const feedDisplaySettings = {
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: initialFeedSlide,
            draggable: false,
            rtl: true,
            beforeChange: (current, next) => {
                this.setState({ feedActiveSlide: Math.abs(next - this.getListWithAds().length + 1) });
                this.onFeedArrowClick();
            },
            nextArrow: <SampleNextArrow feedActiveSlide={feedActiveSlide} listLength={this.getListWithAds().length} pushGA4Event={this.pushGA4Event} onClick={this.onFeedArrowClick} />,
            prevArrow: <SamplePrevArrow feedActiveSlide={feedActiveSlide} listLength={this.getListWithAds().length} pushGA4Event={this.pushGA4Event} />,
        };

        return (
            <div className="videoGalleryVerticalDesktopWrapper">
                {isTabVisible && <div className="videoGalleryVerticalTab componentaTab"><span className="tabIcon"></span><span>{tabTitleText}</span></div>}
                {isFeedDisplay && <div className="closeBtn" onClick={this.onCloseVideoGalleryView}></div>}
                <PromoCarousel
                    imageWidth={273}
                    width={1240}
                    itemListLength={videoList.length}
                    marginRight={20}
                    togglePromoActive={togglePromoActive}
                    currentPromoIndex={currentPromoIndex}
                    videoList={videoList}
                    pushGA4Event={this.pushGA4Event}
                >
                    {videoList.map((videoItem, index) => {
                        return (
                            <VideoGalleryVerticalItem
                                videoItem={videoItem}
                                onOpenVideoGalleryView={this.onOpenVideoGalleryView}
                                videoIndex={index}
                                key={videoItem.itemId}
                                onPromoMouseHover={this.onPromoMouseHover}
                            />
                        )
                    })}
                </PromoCarousel >
                {isFeedDisplay &&
                    <div className="feedDisplay">
                        <Slider {...feedDisplaySettings} className="slider" ref={slider => (this.slider = slider)} >
                            {this.getListWithAds().map((videoItem, index) => {
                                if ((typeof videoItem) === 'string') {
                                    return (<div key={index} id={videoItem} className="ads_vertical_video"></div>)
                                } else {
                                    return (
                                        <VideoVerticalFeedItem
                                            videoItem={videoItem}
                                            key={videoItem.itemId}
                                            feedActiveSlide={feedActiveSlide}
                                            onNextSlide={this.onNextSlide}
                                            onCloseVideoGalleryView={this.onCloseVideoGalleryView}
                                            domain={domain}
                                            index={index}
                                            showPrerollAds={showPrerollAds} />
                                    )
                                }
                            })}
                        </Slider>
                    </div>
                }
            </div>
        )
    }
}

const SampleNextArrow = (props) => {
    const { onClick, feedActiveSlide, pushGA4Event } = props;
    const shouldDisplayArrow = feedActiveSlide !== 0;
    const handleClick = () => {
        onClick();
        pushGA4Event("swipe_right", true);
    }

    return (
        <button
            className={`slickArrow slickNext ${shouldDisplayArrow ? "" : "slickDisabled"}`}
            onClick={handleClick}
        ></button>
    );
}

const SamplePrevArrow = (props) => {
    const { onClick, feedActiveSlide, listLength, pushGA4Event } = props;
    const shouldDisplayArrow = (feedActiveSlide + 1) !== listLength;
    const handleClick = () => {
        onClick();
        pushGA4Event("swipe_left", true);
    }
    return (
        <button
            className={`slickArrow slickPrev ${shouldDisplayArrow ? "" : "slickDisabled"}`}
            onClick={handleClick}
        ></button>
    );
}