
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { Group } from "../siteYnetHeaderComponenta";
import { HamburgerMenuItems } from "../../../../ctechWidgets/ctechHeader/components/ctechMenuList";



interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisible: boolean
    onClose: () => void
    ynetSearchPageLink: string
    linkToTw: string
    linkToFb: string
    linkToYtube: string
    linkToInst:string
    linkToTelegram:string
}
interface menuPopUpState {
    isSearchInputVisible: boolean
    userInput: string
}

declare let window: Window & {
    languageCode: string
}

export class HeaderScrolledMenu extends React.Component<MenuPopUpProps, menuPopUpState> {
    constructor(props) {
        super(props)

        this.state = {
            isSearchInputVisible: false,
            userInput: "",
        }

    }
    private toggleSearch = () => {
        this.setState({ isSearchInputVisible: !this.state.isSearchInputVisible })
    }
    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }
    private search = () => {
        const { userInput: typedUserInput } = this.state;
const {ynetSearchPageLink} = this.props
const language = (typeof window !== "undefined" &&  window.languageCode)  || "";
const siteUrl = language.includes("ru") ? "www.vesty.co.il" : "www.ynetnews.com"; 
const siteQ = language.includes("ru") ? "vesty_q" : "ynetnews_q"; 
        if (!typedUserInput) {
            return
        }

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let site = ""


        urlHead =  `https://www.google.com/search?${siteQ}=`;
        userInput = typedUserInput
        site = `&q=site:${siteUrl}+${typedUserInput}`;
        fullUrl = urlHead + userInput + site;
        fullUrl
        window.open(fullUrl, '_blank');
    }


    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }
    //https://www.google.com/search?ynet_q=fdgfd&q=site%3Am.ynet.co.il+fdgfd
    private clickAcessibilityBtn = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    render() {
        const { groupsWithItems, isVisible, ynetSearchPageLink, onClose, linkToTw, linkToFb, linkToYtube,linkToInst, linkToTelegram} = this.props
        const { isSearchInputVisible, userInput } = this.state
        const isVisibleClassName = isVisible ? "opened" : "closed";
        const isfollowUsLinks = linkToTw || linkToFb || linkToYtube
        return (
            <div className={`HeaderScrolledMenu  ${isVisibleClassName}`} role="sub-navigation" aria-label="Sub Menu">
                <div className="hamTopArea">
                    <div>
                        <div className="searchArea">
                            <button className="srhBtn" onClick={this.toggleSearch} />
                            {isSearchInputVisible && <input
                                alt="חיפוש"
                                className="mutamSrchBox"
                                id="mutamSrchBox"
                                aria-label="חיפוש"
                                type="text"
                                value={userInput}
                                onChange={this.updateInputValue}
                                onKeyPress={this.handleInputKeyPress}

                            />}
                        </div>
                        <div id="mainAccessibilityButton" >
                            <a id="INDmenu-btn" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button" onClick={this.clickAcessibilityBtn}>

                            </a></div>
                    </div>
                    <button className="closeBtn" onClick={onClose} />
                </div>

                <div className="linksGroups">

                    {_.map((groupsWithItems), (item: Group, index: number) =>
                        <div className="group" key={index}>
                            <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                            <ul className="groupList">
                                {_.map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                    <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} style={{ color: subItem.color }}>
                                        <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                    </HyperLinkBuilder></li>
                                )}
                            </ul>
                        </div>
                    )}
                    {isfollowUsLinks && <div className="followUsLinks">
                        {linkToTw !== "" && <SimpleLinkRenderer href={linkToTw} target="_blank" className="mediaIcon linkToTw" />}
                        {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                        {linkToYtube !== "" && <SimpleLinkRenderer href={linkToYtube} target="_blank" className="mediaIcon linkToYtube" />}
                        {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="mediaIcon linkToInst" />}
                    </div>}
                </div>

            </div>)
    }
}