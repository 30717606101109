import { enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils"
import { SiteVideoData } from "widgets/interfaces"
import { buildAdTag } from "../../../../siteWidgets/videoPlayer/videoPlayerAds"
import { SiteVideoInline } from "../../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline";

interface LiveVideoPlayerProps {
    videoSource: string
    isCustomControllers?: boolean
    lang?: string
}

interface LiveVideoPlayerPropsState {
    preRollAdPlayed: boolean
    isMinimizedMode: boolean
}

export class LiveVideoPlayer extends React.Component<LiveVideoPlayerProps, LiveVideoPlayerPropsState> {

    constructor() {
        super();
        this.state = {
            preRollAdPlayed: false,
            isMinimizedMode: false,
        };
    }

    private playerRef;
    private intersectionObserver: IntersectionObserver;

    componentDidMount() {
        enablePageRefresh();
        this.initObserver();
    }

    componentWillUnmount() {
        this.intersectionObserver && this.intersectionObserver.disconnect();
    }

    private initObserver = () => {
        this.intersectionObserver = new IntersectionObserver(entry => {
            const observedPlayerDiv = document.getElementById('player');
            const ismuted = observedPlayerDiv.classList.contains("is-muted");
            const shouldDisplayMinimizedMode = !ismuted && !entry[0].isIntersecting && !this.state.isMinimizedMode;
            if (shouldDisplayMinimizedMode) this.setState({ isMinimizedMode: true });
        })
        this.intersectionObserver.observe(this.playerRef);
    }

    private getFlowPlayerData = (): SiteVideoData => {
        const { videoSource } = this.props;
        return {
            mediaType: "MEDIA_VIDEO",
            url: videoSource,
            downGradeUrl: videoSource,
            poster: "",
            youtube_id: "",
            blockAbroadViews: false,
            isLive: true,
            isAutoPlaying: true,
            isPlayerBarVisible: true,
            isSoundButtonVisible: true,
            isMuteAvailable: true
        }
    }

    private handlePlayerLoaded = (player) => {
        const { lang } = this.props

        let browserDisplayMediaMetaData = { title: "Ynetnews-live", artist: "ynetnews", album: "Ynetnews" }; 
        if (lang === "ru") {
            browserDisplayMediaMetaData = { title: "Vesty-live", artist: "vesty", album: "vesty" };
        }
        if (browserDisplayMediaMetaData && "mediaSession" in navigator) {
            //@ts-ignore
            navigator.mediaSession.metadata = new MediaMetadata(
                browserDisplayMediaMetaData
            );
        }

        const that = this;
        player.setOpts({ ima: null });
        player.setOpts({ muted: this.state.isMinimizedMode ? false : true });
        player.play();
        setImmediate(() => {
            player.on("volumechange", function (e) { that.onStartAds(player, e) })
            player.ads.on("ad-completed", function () {
                that.setState({ preRollAdPlayed: true })
                player.setOpts({ muted: false });
                player.play();
            })
        })

        setImmediate(() => player.ads.on("ad-completed", function () {
            that.setState({ preRollAdPlayed: true })
            player.setOpts({ muted: false });
            player.play();
        }))

    }

    private onStartAds = (player, e) => {
        if (this.state.preRollAdPlayed) return;
        player.setOpts({ ima: { locale: "he", ads: [] } });
        const adTag = buildAdTag("Preroll", false, true, "fpn");
        player.ads.requestAd(adTag);
    }

    private onMinimizedModeToggle = () => {
        this.setState({ isMinimizedMode: !this.state.isMinimizedMode });
    }

    private onMinimizedPlayerPlayToggler = () => {
        const playerElement: any = document.querySelector('.minimizedPlayer-pf .fp-engine');
        if (playerElement) {
            playerElement.togglePlay();
            const toggleBtn = document.querySelector(".minimizedPlayerToggleBtn");
            toggleBtn.classList.toggle("playBtn");
        }
    }

    public render() {
        const { isMinimizedMode } = this.state;
        const { isCustomControllers, lang } = this.props;

        let text = 'live';
        if (lang === "ru") {
           text = 'прямой эфир';
        }

        return (
            <div className="YITFloatingPlayer is-sticky" ref={ref => this.playerRef = ref}>
                {isMinimizedMode ?
                    <div className="minimizedPlayer-pf" id="minimized-player">
                        <div className="videoPlayerWrapper">
                            <SiteVideoInline
                                data={this.getFlowPlayerData()}
                                applyStickyness={true}
                                hideVideoAds
                                isShareButton={false}
                                onPlayerLoaded={this.handlePlayerLoaded}
                            />
                        </div>
                        <div className="YITFloatingPlayerCloseButton" onClick={this.onMinimizedModeToggle}></div>
                        <div className="content">
                            <div className="textDiv">{text}</div>
                            <div className="minimizedPlayerToggleBtn" onClick={this.onMinimizedPlayerPlayToggler}></div>
                        </div>
                    </div>
                    :
                    <>
                        {isCustomControllers &&
                            <div className="customControllers">
                                <div className="controller stickyController" onClick={this.onMinimizedModeToggle} ></div>
                            </div>
                        }
                        <SiteVideoInline
                            data={this.getFlowPlayerData()}
                            applyStickyness={true}
                            hideVideoAds
                            isShareButton={false}
                            onPlayerLoaded={this.handlePlayerLoaded}
                        />
                    </>
                }
            </div>
        )
    }
}



