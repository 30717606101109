
type DateFormat = "shortYear" | ""

export const getHourAndMinutes = (date: Date) => {
    return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
}

export const getFullDate = (date: Date, devideUnitChar: string = ".", format: DateFormat = "") => {
    switch (format) {
        case "shortYear":
            return `${date.getDate()}${devideUnitChar}${date.getMonth() + 1}${devideUnitChar}${`${date.getFullYear()}`.slice(2)}`
            break;

        default:
            return `${date.getDate()}${devideUnitChar}${date.getMonth() + 1}${devideUnitChar}${date.getFullYear()}`
    }

}

export const isSameDate = (date1: Date, date2: Date) => {
    return (getFullDate(date1).localeCompare(getFullDate(date2))) === 0
}

export const scrollToElement = (id: string, block: ScrollLogicalPosition = "start", inline: ScrollLogicalPosition = "nearest") => {
    const el = document.querySelector(`.${id}`)
    el && el.scrollIntoView({ behavior: "smooth", block: block, inline: inline })
}

export const isToday = (date: Date) => {
    const today = new Date()
    return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
}

export const isYesterday = (date: Date) => {
    const yesterday = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24); // love was such an easy game to play
    return date.getDate() == yesterday.getDate() &&
        date.getMonth() == yesterday.getMonth() &&
        date.getFullYear() == yesterday.getFullYear()
}

export const isXMinutesOld = (date: Date, minutes: number) => {
    if (!isToday(date)) return true;
    const today = new Date();
    let diffMs = (date.getTime() - today.getTime()); // milliseconds between now & date  
    let diffMins = Math.abs(Math.round(((diffMs % 86400000) % 3600000) / 60000)); // minutes

    return diffMins >= minutes
}

export const getHowLongAgo = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffInMinutes = diff / 1000 / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInWeeks = diffInDays / 7;
    const diffInMonths = diffInDays / 30;
    const diffInYears = diffInDays / 365;

    if (diffInMinutes < 60) {
        return `לפני ${Math.floor(diffInMinutes)} דק'`;
    } else if (diffInHours < 24) {
        return `לפני ${Math.floor(diffInHours)} שע'`;
    } else if (diffInDays < 2) {
        return "אתמול";
    } else if (diffInDays < 30) {
        return `לפני ${Math.floor(diffInDays)} ימים`;
    } else if (diffInDays < 365) {
        return `לפני ${Math.floor(diffInWeeks)} שבועות`;
    } else if (diffInDays < 365 * 2) {
        return `לפני ${Math.floor(diffInMonths)} חודשים`;
    } else if (diffInDays < 365 * 3) {
        return "שנה שעברה";
    } else {
        return `לפני ${Math.floor(diffInYears)} שנים`;
    }
};