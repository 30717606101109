import * as map from "lodash/map"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"

export interface HamburgerMenuItems {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    color: string
    bgColor?: string
    isSelected?: boolean
}
export interface BottomLinksItems {
    name: string
    title: string
    url: string
}

interface MenuProps {
    menuItems: HamburgerMenuItems[]
    width?: number
    isOpenedHam: boolean
    bottomLinksItems: BottomLinksItems[]
}

export class CtechMenuList extends React.Component<MenuProps, {}> {
    constructor(props) {
        super(props)

    }
    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
    }
    render() {
        const { menuItems, width, isOpenedHam, bottomLinksItems } = this.props
        let style = width ? { width: `${width}px` } : {}
        const opened = isOpenedHam ? "opened" : "";
        return (

            <div className={`CtechMeniList CalcalistMeniList ${opened}`} style={style} >
                <ul className="menuList">
                    {map(menuItems, (item, i) =>
                        <li key={i} className="linkListItem">
                            <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} style={{ color: item.color, background: item.bgColor }}>
                                <span dangerouslySetInnerHTML={{ __html: item.title }} />
                            </HyperLinkBuilder>
                        </li>)}
                </ul>

                <div className="bottomLinks">
                    <div className="linksWithIcons">
                        {map(bottomLinksItems, (link, i) =>
                            <SimpleLinkRenderer href={link.url} title={link.title} className={`bottomLink`} key={i}>
                                <span className={`icon ${link.name}`}></span>
                                <span className={`bottomLinkTitle ${link.name}`}>{link.title}</span>
                            </SimpleLinkRenderer>
                        )}
                        <span id="mainAccessibilityButton" className="AccessibilityLink"  >
                            <div className={`bottomLink`} id="INDmenu-btn3" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button"
                                onClick={this.clickAcessibilityBtn}>
                                <div className={`icon Accessibility`}></div>
                                <span className="bottomLinkTitle">ACCESSIBILITY</span>
                            </div></span>
                    </div>
                </div>
            </div>
        )


    }
}
