
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { Group } from "../siteYnetHeaderComponenta";
import { HamburgerMenuItems } from "../../../../ctechWidgets/ctechHeader/components/ctechMenuList";
import { YnetPremiumLogin } from "../../../../ynetSearch/components/ynetPremiumLogin/ynetPremiumLogin";
import { YnetPianoLogin, YnetPlusUserMenuItems } from "../../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin";



interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisible: boolean
    onClose: () => void
    ynetSearchPageLink: string
    linkToTw: string
    linkToFb: string
    linkToYtube: string
    yplusLink: string
    promoText: string
    promoLink: string
    redMailLink: string
    isPianoLogin:boolean
    layout?:string
    ynetPlusMenuItems?:YnetPlusUserMenuItems[]
    domain?:string
}
interface menuPopUpState {
    isSearchInputVisible: boolean
    userInput: string
}

export class HeaderScrolledMenu extends React.Component<MenuPopUpProps, menuPopUpState> {
    constructor(props) {
        super(props)

        this.state = {
            isSearchInputVisible: false,
            userInput: "",
        }

    }

    private toggleSearch = () => {
        this.setState({ isSearchInputVisible: !this.state.isSearchInputVisible })
    }

    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }

    private search = () => {
        const { userInput: typedUserInput } = this.state;

        if (!typedUserInput) {
            return
        }

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let site = ""


        urlHead = 'https://www.google.com/search?ynet_q='
        userInput = typedUserInput
        site = `&q=site:www.ynet.co.il+${typedUserInput}`
        fullUrl = urlHead + userInput + site

        fullUrl
        window.open(fullUrl, '_blank');
    }

    private clickAcessibilityBtn = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    private clickRevokeBtn = (e) => {
        e.preventDefault();
        typeof window !== 'undefined' &&  window.googlefc && window.googlefc.callbackQueue.push({'CONSENT_DATA_READY': () => window.googlefc.showRevocationMessage()})
    }

    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }
    //https://www.google.com/search?ynet_q=fdgfd&q=site%3Am.ynet.co.il+fdgfd

    render() {
        const { groupsWithItems, isVisible, ynetSearchPageLink, onClose, linkToTw, linkToFb, linkToYtube, promoText, promoLink, yplusLink, redMailLink, isPianoLogin,ynetPlusMenuItems,layout,domain} = this.props
        const { isSearchInputVisible, userInput } = this.state
        const isVisibleClassName = isVisible ? "opened" : "closed";
        const isfollowUsLinks = linkToTw || linkToFb || linkToYtube
        const showAbroad = typeof window !== 'undefined' && window.isAbroad

        return (
            <div className={`HeaderScrolledMenu  ${isVisibleClassName}`} role="sub-navigation" aria-label="Sub Menu">
                <div className="hamTopArea">
                    <div>
                        <div className="searchArea">
                            <button className="srhBtn" onClick={this.toggleSearch} />
                            {isSearchInputVisible && <input
                                alt="חיפוש"
                                className="mutamSrchBox"
                                id="mutamSrchBox"
                                aria-label="חיפוש"
                                type="text"
                                value={userInput}
                                onChange={this.updateInputValue}
                                onKeyPress={this.handleInputKeyPress}

                            />}
                        </div>
                        <div id="mainAccessibilityButton">
                            <a id="INDmenu-btn" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button" onClick={this.clickAcessibilityBtn}>

                            </a></div>

                        <SimpleLinkRenderer className="redMailHeaderBtn" href={redMailLink} target="_blank">
                            <div className="redmailIcon"></div>
                            <span className="redMailTitle">המייל האדום</span>
                        </SimpleLinkRenderer>
                      
                        {showAbroad && <div className="revokeButtonContainer">
                            <a role="button" id="revokeButton"  onClick={this.clickRevokeBtn}></a> 
                            <span>Privacy settings</span>                  
                        </div>}

                    </div>
                    <button className="closeBtn" onClick={onClose} />
                </div>

                <div className="linksGroups">
                {isPianoLogin ? <YnetPianoLogin domain={domain} layout={layout} ynetPlusMenuItems={ynetPlusMenuItems} promoText={promoText} promoLink={promoLink}  yplusLink={yplusLink}/>  :  <YnetPremiumLogin promoText={promoText} promoLink={promoLink} yplusLink={yplusLink} isYnet1280={true} />}
            

                    {_.map((groupsWithItems), (item: Group, index: number) =>
                        <div className="group" key={index}>
                            <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                            <ul className="groupList">
                                {_.map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                    <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} style={{ color: subItem.color }}>
                                        <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                    </HyperLinkBuilder></li>
                                )}
                            </ul>
                        </div>
                    )}
                    {isfollowUsLinks && <div className="followUsLinks">

                        {linkToTw !== "" && <SimpleLinkRenderer href={linkToTw} target="_blank" className="mediaIcon linkToTw" />}
                        {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                        {linkToYtube !== "" && <SimpleLinkRenderer href={linkToYtube} target="_blank" className="mediaIcon linkToYtube" />}

                    </div>}
                </div>

            </div>)
    }
}