import { VideoSettingsData, LinkedItemType, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { SiteItemData, SiteMediaData, SiteImageData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { isHavakVisible, PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { MediaContentTypes } from "constants/contentTypes"
import * as classNames from "classnames"
import { MarketingSign } from "../../../../commonComponents/inline/marketingSign/marketingSign"
import { LabelText } from "../../topStory/components/labelText";

interface MultiArticlesSectionItemFrontProps {
    imageWidth: number
    imageHeight: number
    item?: SiteItemData
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    marginLeft?: number
    index: number
    lastHovered: number
    slotHoverHandler: (slotIndex: number) => void
}

export class MultiArticlesSectionItemFront extends React.Component<MultiArticlesSectionItemFrontProps>{
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }

    public render() {
        const { imageWidth, item, imageHeight, videoSettings, marginLeft, fieldsDisplaySettings, index, lastHovered, slotHoverHandler } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const slotClasses = `slotView${lastHovered === index ? " active" : ""}`;
        const isPlus = isHavakVisible({ premiumExpireDays: item.premiumExpireDays, dateUpdatedOnSite: item.dateUpdatedOnSite });
           let moreInfo;
        const isChannelVisible = fieldsDisplaySettings.isChannelNameVisible
        const isAuthor = fieldsDisplaySettings.isAuthorVisible
        const isSeparatorAfterChannel = isChannelVisible && item.subchannelName && item.subchannelName.length>0;
        const isSeparatorAfterAuthor = isAuthor && item.author && item.author.length>0 ;
        const separatorAfterChannel = isSeparatorAfterChannel ? <span className="separator">|</span> : "";
        const separatorAfterAuthor = isSeparatorAfterAuthor ? <span className="separator">|</span> : "";
        if (isChannelVisible || isAuthor) {
            let authorName;
            const channel = isSeparatorAfterChannel && <span className="channel" data-tb-category>{item.subchannelName} </span>;

            if (isSeparatorAfterAuthor) {
                authorName = <span className="authorInfo" data-tb-author>{item.author}</span>
            }
       

            moreInfo = <span className="moreInfoRow">{channel}{separatorAfterChannel}{authorName}{separatorAfterAuthor}</span>
        }

        return (
            <div className={slotClasses} style={{ marginLeft: marginLeft, width: 190, height: 372 }} onMouseEnter={() => slotHoverHandler(index)}>
                <div className="imageArea">
                    <SlotIconDisplay itemId={item.itemId} icon={this.getIcon(item.icon, item.hasVideo)} iconClassName="medium" isIconVisible={item.hasVideo} />
                    {!isPlus &&
                            item.isMarketingContent && <MarketingSign isSmallItem={false} marketingContentText={item.marketingContentText} languageCode={"he-IL"} />}
                    { !isPlus &&  <LabelText className="basicLabel" labelText={item.redLabelText} mediaIconPlace={item.textPlace} onLabelTextChange={() => null} />}
                   <PayWallArctilceIcon
                        premiumExpireDays={item.premiumExpireDays}
                        dateUpdatedOnSite={item.dateUpdatedOnSite}
                        className={`onImage`} />
                    <div className="MediaCarousel" style={{ width: 190, height: 300 }}>
                        <SiteMedia
                            data={item.media !== undefined ? item.media : emptyMedia}
                            width={imageWidth}
                            height={imageHeight}
                            videoSettings={videoSettings}
                            itemLinkUrl={item.titleLink}
                            isImageLinkable={true}
                            linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        />
                    </div>
                </div>

                <div className={`slotTextArea ${fieldsDisplaySettings.isRoofTitleVisible ? "" : "noRoof"}`}>
                    {fieldsDisplaySettings.isRoofTitleVisible && <div className="slotRoofTitle" dangerouslySetInnerHTML={{ __html: item.roofTitle }} />}

                
                    {fieldsDisplaySettings.isTitleVisible && <div className={classNames("slotTitle medium", item.isCommertial ? "commertialTitle" : "")}>
                        <HyperLinkBuilder
                            href={item.titleLink}
                            linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                            children={item.title}>
                            <div dangerouslySetInnerHTML={{ __html: item.title }}/>
                        </HyperLinkBuilder>
                    </div>}
                    <a className="moreDetails" href={item.titleLink}>
                        {moreInfo}
                     
                        {item.itemType === LinkedItemType.ARTICLE && <DateDisplay date={item.dateUpdatedOnSite} isTbDate />}
                    </a>
                </div>
                <div className={classNames("commertialTitleLine", item.isCommertial ? "showLine" : "")}> </div>
            </div>
        )
    }
}