import { SiteVideoData } from "../../../../interfaces";
import {
    shouldAutoPlayVideo,
    getLogoUrl,
    getVideoSources,
} from "../../videoPlayerUtils";
import { getImaSettings } from "../../videoPlayerAds";
import { sendPopularityEvent } from "../../videoAnalytics";
import {
    SiteSettingsHOC,
    SiteSettings,
} from "../../../../../global/components/siteSettingsHOC";
import {
    disablePageRefresh,
    enablePageRefresh,
} from "../../../siteWidgetUtils";
import * as classNames from "classnames";
import { attachFpStickinessAPI } from "../../videoStickiness";

interface NewFlowPlayerForInlineProps {
    data: SiteVideoData;
    hideVideoAds?: boolean;
    adIndex?:number;
    isShareButton?: boolean;
    videoShareLink?: string
    runArticleAutoPlayLogic?: boolean;
    isArticle?: boolean;
    applyStickyness: boolean;
    onSelect?: () => void;
    onPlayStarted?: () => void;
    onPlayStopped?: () => void;
    onVideoEnded?: () => void
    onVideoError?: () => void
    width?: number;
    height?: number;
    onPlayerLoaded?: (player) => void;
}

interface combinedProps extends Partial<SiteSettings> {}

interface NewFlowPlayerForInlineState {
    isMuted: boolean;
    isWindowShareOpen: boolean;
    isPopularitySent: boolean;
}

const dimensionNumber = {
    ynet: {
        videoType: 5,
        videoLen: 7,
        videoTitle: 8,
        videoId: 9,
        liveVideo: 10,
        videoCh: 11,
    },
    ynetespanol: {
        videoType: 5,
        videoLen: 7,
        videoTitle: 8,
        videoId: 9,
        liveVideo: 10,
        videoCh: 11,
    },
    ynetnews: {
        videoType: 5,
        videoLen: 7,
        videoTitle: 8,
        videoId: 9,
        liveVideo: 10,
        videoCh: 11,
    },
    calcalist: {
        videoType: 13,
        videoLen: 15,
        videoTitle: 16,
        videoId: 17,
        liveVideo: 24,
        videoCh: 23,
    },
    vesty: {
        videoType: 5,
        videoLen: 7,
        videoTitle: 8,
        videoId: 9,
        liveVideo: 10,
        videoCh: 11,
    },
};

const acceptedFloatingWidth = 1600;
@SiteSettingsHOC()
export class FlowPlayerForInline extends React.Component<
    NewFlowPlayerForInlineProps & combinedProps,
    NewFlowPlayerForInlineState
> {
    private playerContainer: HTMLDivElement;
    private flowplayerApi: any;
    constructor(props: NewFlowPlayerForInlineProps) {
        super(props);
        this.state = {
            isMuted: false,
            isWindowShareOpen: true,
            isPopularitySent: false,
        };
    }

    public componentDidMount() {
        const { isMuteAvailable, isLive } = this.props.data;
        this.setState({ isMuted: isMuteAvailable && !isLive }, () => {
            this.initializePlayer();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.initializePlayer();
        }
    }

    createDimensions = () => {
        const { isLive, title, mediaId } = this.props.data;
        const siteID = window && window["siteID"];
        const keysValue = [
            "Flowplayer",
            0,
            title ? title : "",
            mediaId,
            isLive ? true : false,
            siteID,
        ];
        const dimensions: Object = {};
        if (siteID) {
            let i = 0;
            for (let key in dimensionNumber[siteID]) {
                const dimKey = "dimension" + dimensionNumber[siteID][key];
                Object.assign(dimensions, { [dimKey]: keysValue[i] });
                i++;
            }
        }
        return dimensions;
    };

    private getEventActions = () => {
        const isGa4 = window && window["isGa4"]
        if (isGa4)
            return {
                ad_start_preroll: "media_start_preroll",
                ad_completed_preroll: "media_completed_preroll",
                live_start: "media_start",
                video_start: "media_start",
                live_click_play: "media_click_play",
                video_click_play: "media_click_play",
                live_pause: "media_pause",
                video_pause: "media_pause",
                live_resume: "media_resume",
                video_resume: "media_resume",
                live_mute: "media_mute",
                video_mute: "media_mute",
                live_unmute: "media_unmute",
                video_unmute: "media_unmute",
                video_25_percent: "media_watch_25_percent",
                video_50_percent: "media_watch_50_percent",
                video_75_percent: "media_watch_75_percent",
                video_complete: "media_finish",
            };
        return { 
            video_player_load: "Video Player Loaded",
            fullscreen_enter: "Enter Fullscreen",
            fullscreen_exit: "Exit Fullscreen",
            video_click_play: "Play - Video Starts ",
            video_pause: "Pause",
            video_resume: "Resume",
            video_mute: "Mute",
            video_unmute: "Unmute",
            video_25_percent: "Played - 25%",
            video_50_percent: "Played - 50%",
            video_75_percent: "Played - 75%",
            video_complete: "Video Completed",
            live_click_play: "Play - Video Starts ",
            live_pause: "Pause",
            live_resume: "Resume",
            live_mute: "Mute",
            live_unmute: "Unmute",
            live_complete: "Video Completed",
            ad_start_preroll: "Ad Start Preroll [x]",
            ad_start_midroll: "Midroll Ad Start",
            ad_start_postroll: "Ad Start Postroll [x]",
            ad_completed_preroll: "Preroll Ad Completed",
            ad_completed_midroll: "Mideroll Ad Completed",
            ad_completed_postroll: "Postroll Ad Completed",
            ad_skipped_preroll: "Preroll Ad Skipped",
            ad_skipped_midroll: "Midroll Ad Skipped",
            ad_skipped_postroll: "Postroll Ad Skipped",
        };
    };

    private initializePlayer = () => {
        const {
            url,
            downGradeUrl,
            poster,
            isAutoPlaying,
            isLoopPlaying,
            isPlayerBarVisible,
            mediaId: videoId,
            isLive,
            title,
            credit,
            aspectRatio,
            mediaId,
            duration,
            videoType,
            categoryId,
        } = this.props.data;

        const {
            runArticleAutoPlayLogic,
            hideVideoAds,
            onPlayStarted,
            onPlayerLoaded,
            videoShareLink,
            onVideoEnded,
            onVideoError,
            adIndex,
        } = this.props;
        const container = this.playerContainer;
        const shouldAutoPlay =
            (!runArticleAutoPlayLogic && isAutoPlaying) ||
            shouldAutoPlayVideo(container, isAutoPlaying);
        const dataLayerFirstChild = window && window.dataLayer && window.dataLayer[0];


        const article_data = dataLayerFirstChild ? {
            article_id: dataLayerFirstChild.articleID,
            author_name: dataLayerFirstChild.authorName,
            date_modified: dataLayerFirstChild.dateModified,
            date_published: dataLayerFirstChild.datePublished,
            videos_count: dataLayerFirstChild.videosCount,
            sponsored_content: dataLayerFirstChild.sponsoredContent,
            word_count: dataLayerFirstChild.wordCount,
            article_tags: dataLayerFirstChild.articleTags,
            comments_count: dataLayerFirstChild.commentsCount,
            dc_path: dataLayerFirstChild.dcPath,
            page_title: dataLayerFirstChild.pageTitle,
            cid: dataLayerFirstChild.cid,
            page_type: dataLayerFirstChild.pageType,
            ad_blocker: dataLayerFirstChild.adBlocker,
            location_permission: dataLayerFirstChild.locationPermission,
            channel_name: dataLayerFirstChild.channelName,  
            media_type: videoType || "video",
            media_length: duration,
            media_title:title,
            media_id:mediaId,
            media_location: dataLayerFirstChild.articleID? "article" : "homepage",
            media_player_name:'flowplayer',
            media_url: url,
            media_autoplay: isAutoPlaying? 'true': 'false',
            media_live: isLive ? 'true' : 'false',
        } : {};

        const token = window && window["fpToken"];
        const analyticCode = window && window["analyticsCode"];
        const popularityLink = window && window["WCM_POPULARITY_LINK"];
        const isFloating =
            (window &&
                window["fpIsFloating"] &&
                window.innerWidth > acceptedFloatingWidth) ||
            false;
        const player_lang =
            (document as any).querySelector(":root").lang || "en";

        const settings: any = {
            token,
            src: getVideoSources(url, downGradeUrl),
            poster: poster,
            muted: this.state.isMuted,
            autoplay: shouldAutoPlay,
            fullscreen: !isPlayerBarVisible,
            live: isLive,
            loop: isLoopPlaying,
            float_on_scroll: false,
            ratio: "16:9",
            plugins: ["cuepoints"],
            lang: player_lang,
            multiplay: false,
            preload: "none",
            title: window && window["siteID"]==="calcalist" ? `${title || credit || ""}` : `${videoId}#${title || credit || ""}`,
            share: videoShareLink ? {link: videoShareLink} : false,
            ga: {
                ga_instances: [analyticCode],
                custom_data: {...this.createDimensions(),...article_data},
                event_actions: this.getEventActions(),
                event_categories: {
                    live: "Video",
                    videos: "Video",
                },
            },
            cuepoints: [
                {
                    start: 0.5,
                    end: 1,
                    startTime: 0.5,
                    endTime: 1,
                    msg: "Cuepoint 1",
                },
            ],
        };
       

        if (!hideVideoAds) {
            settings.ima = getImaSettings(shouldAutoPlay, true, "fpn", null, categoryId, adIndex);
        }

        if (!isLoopPlaying) {
            const logo = (window && window["videoPlayerLogo"]) || getLogoUrl();
            if (logo) {
                settings.logo = logo;
            }
        }

        var player = flowplayer(container, settings);

        if ((flowplayer as any).version[0] === "3") {
            player.on((flowplayer as any).events.CUEPOINT_END, function (e) {
                if (popularityLink && e.data.cuepoint.msg === "Cuepoint 1") {
                    sendPopularityEvent(
                        videoId,
                        player.duration,
                        popularityLink
                    );
                }
            });
        } else {
            player.on("cuepointend", function (e) {
                if (popularityLink && e.data.cuepoint.msg === "Cuepoint 1") {
                    sendPopularityEvent(
                        videoId,
                        player.duration,
                        popularityLink
                    );
                }
            });
        }

        player.on('ready', function(e, api) {
            if (!api.video.title) {
                api.video.title = title;
            }
        });
        

        player.on("playing", function () {
            disablePageRefresh();
            onPlayStarted && onPlayStarted();
        });

        player.on("pause", function () {
            enablePageRefresh();
            //onPlayStopped && onPlayStopped()
        });

        player.on("ended", function () {
            onVideoEnded && onVideoEnded();
        });

        player.on("error", function () {
            onVideoError && onVideoError();
        });

        player.on("beforeplay", function () {
            disablePageRefresh();
            onPlayStarted && onPlayStarted();
        });

        this.flowplayerApi = player;
        onPlayerLoaded && onPlayerLoaded(player);
        isFloating &&
            attachFpStickinessAPI(player, this.playerContainer, poster);
    };

    private getContainerStyle = () => {
        const { width, height } = this.props;

        let style = {};

        if (width && height) {
            style = { width: `${width}px`, height: `${height}px` };
        }

        return style;
    };

    private getClassNames = () => {
        return classNames("use-thin-controlbar");
    };

    public render() {
        const containerStyle = this.getContainerStyle();
        return (
            <div
                id="player"
                className={this.getClassNames()}
                style={{ ...containerStyle, direction: "ltr" }}
                ref={(r) => (this.playerContainer = r)}
            />
        );
    }
}
