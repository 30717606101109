interface MultiImagesLabelsFrontProps {
    width: number
    imageWidth: number
    itemLength: number
    marginRight: number
    isLTR?:boolean
}

interface MultiImagesLabelsFrontState {
    rightOffset: number
    selectedIndex: number
}
export const numberOfImagesToView = (width, imageWidth, marginRight) => {
    const itemWidth = imageWidth + marginRight
    const numberOfImagesToView = Math.floor(width / itemWidth)
    return numberOfImagesToView;
}


export class MultiImagesScrollPerItem extends React.Component<MultiImagesLabelsFrontProps, MultiImagesLabelsFrontState>{

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            rightOffset: 0,
        }
    }

    private handleScrollLeftClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { itemLength, width, imageWidth, marginRight } = this.props
        const single_media_item_width = imageWidth + marginRight
        let newIndex = selectedIndex + 1;
        const scrollBarWidth = width + marginRight
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) * single_media_item_width)
        const newRightOffset = rightOffset - single_media_item_width
        const lastNewOffset = rightOffset - lastScrollingsize
        if (selectedIndex < itemLength - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) - 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === itemLength - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) - 1) {
            this.setState({
                rightOffset: lastNewOffset,
                selectedIndex: newIndex
            })
        }

    }

    private handleScrollRightClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { itemLength: itemList, width, imageWidth, marginRight } = this.props
        const scrollBarWidth = width + marginRight
        const single_media_item_width = imageWidth + marginRight
        let newIndex = selectedIndex - 1
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) * single_media_item_width)
        const firstOffset = rightOffset + lastScrollingsize
        const newRightOffset = rightOffset + single_media_item_width

        if (selectedIndex > 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === 1) {
            this.setState({
                rightOffset: 0,
                selectedIndex: 0
            })
        }
    }

    public render() {
        const { width, imageWidth, itemLength, marginRight, isLTR } = this.props
        const { selectedIndex, rightOffset } = this.state
        let isRightArrowAvailble = isLTR ? selectedIndex !== itemLength - numberOfImagesToView(width + marginRight, imageWidth, marginRight) : selectedIndex !== 0
        let isLeftArrowAvailble =  isLTR ? selectedIndex !== 0 : selectedIndex !== itemLength - numberOfImagesToView(width + marginRight, imageWidth, marginRight)
const style =  { "marginInlineStart": rightOffset };
        return (
            <div className="slotsContent">
                <button
                    disabled={!isRightArrowAvailble}
                    className="scrollRight arrowBtn"
                    aria-label="scroll right"
                    onClick={(e) => isLTR ? this.handleScrollLeftClicked() : this.handleScrollRightClicked()} />
                <div className="frame_forList">
                    <div className="slotListWrapper" style={style} >
                        {this.props.children}</div>
                </div>
                <button
                    disabled={!isLeftArrowAvailble}
                    aria-label="scroll left"
                    className="scrollLeft arrowBtn"
                    onClick={(e) => isLTR? this.handleScrollRightClicked() : this.handleScrollLeftClicked()} />


            </div>)
    }
}
