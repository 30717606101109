import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { MenuPopupComponenta } from "../../../ynet1280/ynetHeader/components/menuPopUpComponenta";
import { MainNavHeaderComponenta } from "./mainNavComponenta";


interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isSearchOpened: boolean
}

interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    logoData: Logo
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToTw: string
    linkToTiktok:string
    enableSticky: boolean
    ynetSearchPageLink: string
    followUsLink:string
    folowUsLinkTarget:LinkTargetProperties
    domain:string
}

export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}

export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}
export interface CommertialItem{
    title:string
    link:string
    linkTarget: LinkTargetProperties
}

export class SiteMagazineHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteMagazineHeaderComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isSearchOpened: false
        }
    }

    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened, isSearchOpened: false, })
    }

    private openSearchMenu = () => {
        const isExternalPage = typeof window != "undefined" && window.isExternalPage;
        if (isExternalPage) {
            top.location.href = 'https://www.ynet.co.il/category/3340';
        }
        else this.setState({ isSearchOpened: !this.state.isSearchOpened, isOpened: false })
    }

    private stickyBar() {
        const headerPos = document.getElementById("MagazineHeader").getBoundingClientRect().top
        if (headerPos + 160 < 0) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())

    }

    componentWillUnmount() {
        const navbar = document.getElementById("YnetHeader")
        document.removeEventListener("scroll", this.throttledScrollHandler())

    }

    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private clickAcessibilityBtn = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        const { mainNavItems, groupsWithItems, logoData,  enableSticky,
          followUsText, linkToInst, linkToFb, linkToTw, linkToTiktok,
            ynetSearchPageLink} = this.props
        const { isSticky } = this.state
        const isStickyAvailable = isSticky && enableSticky;
 
        return (
            <div className={classNames("Magazine2025Header")} id="MagazineHeader">
                <button className="accessibiltyFixedBtn" onClick={this.clickAcessibilityBtn} title="אתר נגיש"  accessKey="m"></button>
                <div className="headerTop">
                    <div className="rightSideLinks">
                        
                         {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="headerIcon instagram" />}
                              {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="headerIcon facebook" />}
                       {(linkToInst || linkToFb) && <div className="iconsSeparator" />}
                        <button className="headerIcon search" onClick={this.openSearchMenu}/>           
                        
                    </div>

                    <div className="logo">
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                            <img src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>

                    </div>
                   
                    <SimpleLinkRenderer href={"/"}  rel="nofollow" className="ylogo" >
                    <img src={"//www.ynet.co.il/images/logo_ynet.png"} alt={"ynet"} title={"ynet"} aria-hidden="true" aria-label="Ynet Logo" />  
                    </SimpleLinkRenderer>
                
                </div>

                <div className={classNames("BottomHeaderArea", { "sticky": isStickyAvailable })} id="BottomHeaderArea">
                    <MainNavHeaderComponenta
                        isSticky={isStickyAvailable}
                        mainNavItems={mainNavItems}
                        logoData={logoData}
                        onToggleMenu={this.openMenu}
                        onToggleSearch={this.openSearchMenu}
                        isOpened={this.state.isOpened}
                    />

                    <MenuPopupComponenta
                        groupsWithItems={groupsWithItems}
                        isVisibleMenu={this.state.isOpened}
                        isVisibleSearch={this.state.isSearchOpened}
                        onCloseMenu={() => this.setState({ isOpened: false })}
                        onCloseSearch={() => this.setState({ isSearchOpened: false })}
                        followUsText={followUsText}
                        linkToInst={linkToInst}
                        linkToFb={linkToFb}
                        linkToTw={linkToTw}
                        linkToYtube={""}
                        linkToTiktok={linkToTiktok}
                        ynetSearchPageLink={ynetSearchPageLink}
                        displayType={"magazine"}
                     
                    />
                </div>
            </div>
        )
    }
}