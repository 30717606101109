

import { VideoSettingsData, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { MultiImagesScroller, getItemMargin } from "widgets/commonComponents/base/multiImagesFrontScroller";
import { SiteItemData } from "widgets/interfaces"
import { MultiImagesItemFront } from "./frontItem"
import { googleScript } from "../../../../widgetsUtils"
import { MultiImagesScrollPerItem } from "widgets/commonComponents/base/multiImagesScrollPerItem"





interface MultiImagesFrontProps {
    itemList: SiteItemData[]
    width: number
    fieldsDisplaySettings: IFieldDisplayData
    videoSettings: VideoSettingsData
    imageWidth: number
    imageHeight: number
    onRowDisplay: boolean
    titleColor: string
    isYplusComp?: boolean
    isLTR?:boolean
    isImageOnly?: boolean
    ads_infrastructure_type?: string
    includeAudioPlayer?:boolean
}



export class MultiImages1280Front extends React.Component<MultiImagesFrontProps>{
    public static siteScriptName = "MultiImages1280Front";



    public render() {
        const { imageWidth, itemList, imageHeight, videoSettings, fieldsDisplaySettings, width, onRowDisplay, titleColor, isYplusComp, isLTR, isImageOnly, ads_infrastructure_type, includeAudioPlayer } = this.props
        const itemStyle = { marginInlineStart: getItemMargin(width, imageWidth)}
        if (onRowDisplay) return (
            <MultiImagesScrollPerItem imageWidth={imageWidth} width={width} itemLength={itemList.length} isLTR={isLTR} marginRight={getItemMargin(width, imageWidth)} >
                {itemList.map((slot, index) => {
                    if (slot.isDFP) return <div id={slot.dfpId} key={index} className={`slotView dfpId ${slot.isCommertial ?  "commertialStyle" : ""}`} style={itemStyle}>
                         {ads_infrastructure_type && ads_infrastructure_type === "new" && <script type="text/javascript" dangerouslySetInnerHTML={{ __html: googleScript(slot.dfpId) }}></script>}
                    </div>
                    else return (
                        <MultiImagesItemFront
                            imageWidth={imageWidth}
                            key={index}
                            imageHeight={imageHeight}
                            videoSettings={videoSettings}
                            fieldsDisplaySettings={fieldsDisplaySettings}
                            item={slot}
                            titleColor={titleColor}
                            margin={getItemMargin(width, imageWidth)}
                            onRowDisplay={onRowDisplay}
                            isYplusComp={isYplusComp}
                            isSmallItem={true}
                            isLTR={isLTR}
                            isImageOnly = {isImageOnly}
                            includeAudioPlayer={includeAudioPlayer}
                        />
                    )
                }
                )}
            </MultiImagesScrollPerItem >)
        else return (
            <div className="slotsContent">{itemList.map((slot, index) => {
                if (slot.isDFP) return <div id={slot.dfpId} key={index} className={`slotView dfpId ${slot.isCommertial ? "commertialStyle" : ""}`} style={{ margin: "0 10px 24px" }} >
                    {ads_infrastructure_type && ads_infrastructure_type === "new" && <script type="text/javascript" dangerouslySetInnerHTML={{ __html: googleScript(slot.dfpId) }}></script>}
                </div>
                else return (
                    <MultiImagesItemFront
                        imageWidth={imageWidth}
                        key={index}
                        imageHeight={imageHeight}
                        videoSettings={videoSettings}
                        fieldsDisplaySettings={fieldsDisplaySettings}
                        item={slot}
                        margin={getItemMargin(width, imageWidth)}
                        onRowDisplay={onRowDisplay}
                        titleColor={titleColor}
                        isYplusComp={isYplusComp}
                        isSmallItem={true}
                        isLTR={isLTR}
                        isImageOnly = {isImageOnly}
                        includeAudioPlayer={includeAudioPlayer}
                    />
                )
            }
            )}  </div>
        )
    }
}