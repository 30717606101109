import { analyticsPushFunc } from "../../../widgetsUtils";

interface FrontCopyButtonProps {
    popupText: string;
    classes?: string;
    children?: React.ReactChild;
    style?: any;
    title?: string;
    url?: string;
    blogPostId?: string;
}

interface FrontCopyButtonState {
    isPopupVisible: boolean;
}

export class FrontCopyButton extends React.Component<
    FrontCopyButtonProps,
    FrontCopyButtonState
    > {
    public static siteScriptName = "FrontCopyButton";
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false,
        };
    }

    private copyToClipboard = async () => {
        const { title, url, blogPostId } = this.props;
    
        const textToCopy = url
            ? url
            : `${window.location.href}?utm_source=ynet.co.il&utm_medium=Share&utm_campaign=copy_url${blogPostId ? `#${blogPostId}` : ""}`;
        
        try {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(textToCopy);
                this.showPopup();
            } else {
                // Fallback for older browsers
                const textarea = document.createElement("textarea");
                textarea.value = textToCopy;
                textarea.style.position = "absolute";
                textarea.style.left = "-9999px"; 
                textarea.style.opacity = "0";
                textarea.setAttribute("readonly", "true");
                document.body.appendChild(textarea);
                textarea.select();
                const successful = document.execCommand("copy");
                if (successful) {
                    this.showPopup();
                } else {
                    console.error("Fallback copy failed");
                }
                document.body.removeChild(textarea);
            }
        } catch (err) {
            console.error("Failed to copy to clipboard:", err);
        }

        if(!url) analyticsPushFunc("copy_url", "Share", title);
    };

    private showPopup = () => {
        this.setState({ isPopupVisible: true }, () => {
            setTimeout(() => {
                this.setState({ isPopupVisible: false });
            }, 3000);
        });
    };


    render() {
        const { children, classes, popupText, style } = this.props;
        const { isPopupVisible } = this.state;
        const buttonClass = classes ? classes : "frontCopyButton-pf";

        return (
            <>
                {isPopupVisible && (
                    <div className="copyPopup" style={style}>
                        {popupText}
                    </div>
                )}
                <div className={buttonClass} onClick={this.copyToClipboard}>
                    {children}
                </div>
            </>
        );
    }
}
