import { SiteItemData, SiteVideoData } from "../../../../interfaces";

interface VideoGalleryVerticalItemProps {
    videoItem: SiteItemData
    videoIndex: number
    isTitleVisible: boolean
    onOpenVideoGalleryView: (e) => void
    onPromoMouseHover: (currentPromoIndex: number) => void
}

interface VideoGalleryVerticalItemState {
    isMouseHover: boolean
}

export class VideoGalleryVerticalItem extends React.Component<VideoGalleryVerticalItemProps, VideoGalleryVerticalItemState>{
    constructor(props) {
        super(props);
        this.state = {
            isMouseHover: false,
        }
    }

    private videoRef;

    private onMouseHover = () => {
        const { videoIndex, onPromoMouseHover } = this.props;
        this.setState({ isMouseHover: true });
        onPromoMouseHover(videoIndex);
        if (this.videoRef) this.videoRef.play();

    }

    private onMouseLeave = () => {
        this.setState({ isMouseHover: false });
        if (this.videoRef) this.videoRef.pause();
    }

    private secondsToMinutesHandler = (seconds: number) => {
        const minutes = Math.floor(seconds / 60).toString().padStart(2, "0");
        const newSeconds = (seconds % 60).toString().padStart(2, "0");
        return `${minutes}:${newSeconds}`;
    }

    public render() {
        const { isMouseHover } = this.state;
        const { videoItem, onOpenVideoGalleryView, videoIndex,isTitleVisible } = this.props;
        const { media, title } = videoItem;
        const { duration, poster, promoPath } = media as SiteVideoData;
        const shouldDisplayPromo = isMouseHover && promoPath;
        const slotClasses = `slotView ${shouldDisplayPromo ? "hovered" : ""}`;

        return (
            <div className={slotClasses} onMouseEnter={this.onMouseHover} onMouseLeave={this.onMouseLeave} onClick={() => onOpenVideoGalleryView(videoIndex)}>
                {isMouseHover ?
                    <video
                        autoPlay={true}
                        loop={true}
                        ref={ref => this.videoRef = ref}
                        muted
                        poster={poster}
                        className="promoVideo" src={promoPath}>
                    </video>
                    :
                    <img
                        src={poster}
                        alt="" />
                }
                <div className="contentDiv">
                    {isTitleVisible && <div className="videoTitle" dangerouslySetInnerHTML={{ __html: title }} />}
                    <div className="videoDuration"><span className="durationLogo"></span> <span>{this.secondsToMinutesHandler(Math.round(duration))}</span></div>
                </div>
            </div>
        )
    }
}
