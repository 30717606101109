
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { Group } from "./siteMagazineHeadeMutamComponenta";
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";




interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisible: boolean
    ynetSearchPageLink: string
    linkToInst: string
    linkToFb: string
    followUsText: string
    layout?: string
    domain?: string
}
interface menuPopUpState {
    userInput: string
}

export class HeaderScrolledMenu extends React.Component<MenuPopUpProps, menuPopUpState> {
    constructor(props) {
        super(props)
        this.state = {
            userInput:""
        }
    }

    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }
    private search = () => {
        const { userInput: typedUserInput } = this.state;

        if (!typedUserInput) {
            return
        }

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let site = ""


        urlHead = 'https://www.google.com/search?ynet_q='
        userInput = typedUserInput
        site = `&q=site:www.ynet.co.il/+${typedUserInput}`
        fullUrl = urlHead + userInput + site

        fullUrl
        window.open(fullUrl, '_blank');
    }


    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }
    

    render() {
        const { groupsWithItems, isVisible, ynetSearchPageLink, linkToFb, linkToInst, followUsText, layout, domain } = this.props
        const { userInput } = this.state
        const isVisibleClassName = isVisible ? "opened" : "closed";
        const isfollowUsLinks = linkToFb || linkToInst
        return (
            <div className={`HeaderScrolledMenu  ${isVisibleClassName}`} role="sub-navigation" aria-label="Sub Menu">
              
                        <div className="searchArea">
                            <button className="srhBtn" onClick={() => this.handleInputKeyPress} />
                            <input
                                alt="חיפוש"
                                className="mutamSrchBox"
                                id="mutamSrchBox"
                                aria-label="חיפוש"
                                type="text"
                                value={userInput}
                                onChange={this.updateInputValue}
                                onKeyPress={this.handleInputKeyPress}

                            />
                        </div>
                 
               
           

                <div className="linksGroups">
                  
                    {_.map((groupsWithItems), (item: Group, index: number) =>
                        <div className="group" key={index}>
                            <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                            <ul className="groupList">
                                {_.map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                    <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} >
                                        <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                    </HyperLinkBuilder></li>
                                )}
                            </ul>
                        </div>
                    )}
                    {isfollowUsLinks && <div className="followUsLinks">
                        {followUsText !== "" && <div className="followUs">{followUsText}</div>}
                        {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                        {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="mediaIcon linkToInst" />}

                    </div>}
                    <SimpleLinkRenderer href={"/"}  rel="nofollow" className="ylogo" >
                    <img src={"//www.ynet.co.il/images/logo_ynet.png"} alt={"ynet"} title={"ynet"} aria-hidden="true" aria-label="Ynet Logo" />  
                    </SimpleLinkRenderer>
                
                </div>

            </div>)
    }
}