require('./calcalist_site_mobileNavigation.less');
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces";
import { SiteSimpleImage } from "../../../../commonComponents/inline/siteSimpleImage";
import * as classNames from "classnames"
import { isWcmEditor } from "../../../../../config/serverConfig";
import throttle = require("lodash/throttle");


interface CalcalistMobileNavigationComponentaState {
    user: { firstName: string }
    isMenuVisible: boolean
    isUserSubscribed: boolean
    isSticky: boolean
    bodyOverflowState: string
}


export interface MobileNavigationMenuItem {
    id: string
    name: string
    url: string
    link: string
    catId: string
    targetLink?: LinkTargetProperties
}


interface CalcalistMobileNavigationComponentaProps {
    menuItems?: MobileNavigationMenuItem[]
    categoryName: string
}

type PianoResponse = {
    user: { firstName: string }
    token: string
}


export class CalcalistMobileNavigationComponenta extends React.Component<CalcalistMobileNavigationComponentaProps & { isPiano: boolean }, CalcalistMobileNavigationComponentaState>{
    constructor(props) {
        super(props);
        if (typeof window != 'undefined') {
            window.tp = window.tp || [];
        }
        this.state = {
            isMenuVisible: false,
            isSticky: false,
            bodyOverflowState: '',
            user: null,
            isUserSubscribed: false
        };
    }

    static siteScriptName = "CalcalistMobileNavigationComponenta"

    private toggleUserMenu = () => {
        this.setState(prevState => ({ isMenuVisible: !prevState.isMenuVisible }), () => {
            if (this.state.isMenuVisible) {
                this.setState({ bodyOverflowState: document.querySelector("body").style.overflow });
                document.querySelector("body").style.overflow = "hidden";
            } else {
                document.querySelector("body").style.overflow = this.state.bodyOverflowState;
            }
        })
    }

    private activateStickyHeader() {
        if (!isWcmEditor()) {
            const headerPos = document.querySelector(".calcalist-mobile-navigation").getBoundingClientRect().top
            if (headerPos < 0) {
                this.setState({ isSticky: true })
            } else {
                this.setState({ isSticky: false })
            }
        }
    }

    componentDidMount() {

        window.tp.push(["init", () => {

            const user = window && window.tp && window.tp.pianoId.getUser();

            if (user) {
                this.setUserSubscriptionStatus(window.tp, user)
            }
        }])

        document.addEventListener("scroll", this.throttledScrollHandler())
        this.activateStickyHeader();
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }

    private throttledScrollHandler = () => throttle(() => this.activateStickyHeader(), 200)

    private discconectUser = () => {
        window.tp.pianoId.logout();
        this.setState({ user: null, isUserSubscribed: false });
        this.toggleUserMenu();
    }

    public loginUser = () => {

        const tp = window.tp || [];

        tp.push(["init", () => {
            tp.pianoId.show({
                width: "360",
                screen: 'login',
                loggedIn: (data: PianoResponse) => {
                    console.log('user ', data.user, ' logged in with token', data.token);
                    this.setUserSubscriptionStatus(tp, data.user);
                },
                loggedOut: function () {
                    console.log('user logged out');
                }
            });
        }]);

        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'GA_Events',
                Category: 'CalcalistPro - UserMenu',
                Action: 'Guest user',
                Label: 'Connect to account'
            });
        }

        this.toggleUserMenu();
    }

    private setUserSubscriptionStatus = (tp, user) => {
        tp.api.callApi("/access/list", {}, (response) => {
            this.setState({ user: user, isUserSubscribed: response.count > 0 });
        });
    }


    private handleMenuItem =(itemName)=>{
        const { user, isUserSubscribed } = this.state
        let action:string;

        if(!user){
            action = "Guest user"
        }else{
            action = isUserSubscribed?"Logged user with subscription": "Logged user without subscription";
        }

        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'GA_Events',
                Category: 'CalcalistPro - UserMenu',
                Action: action,
                Label: itemName
            });
        }

    }


    render() {
        const { menuItems, isPiano, categoryName } = this.props;
        const { isMenuVisible, isSticky, user, isUserSubscribed } = this.state;
        const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;
        if(isApp) return null;
        else  return (
            <div className="show-small-vp">
                <header className={classNames("CalcalistMobileNavigation", { "sticky-header": isSticky })}>
                    <nav>
                        <h3> {categoryName} </h3>

                        <div className={classNames("CalcalistMobileNavigationWrapper", { "FloatToLeft": !isPiano })}>
                            {isPiano && <div onClick={this.toggleUserMenu} className={classNames(user ? "CalcalistLoggedUserIcon" : "CalcalistUserIcon")} />}
                            <a href="https://www.calcalist.co.il"><div className="CalcalistLogo" /></a>
                        </div>
                    </nav>
                </header>
                <div className={classNames("CalcalistMobileNavigationMenu", isMenuVisible && "CalcalistMobileNavigationMenuShow", { "sticky-menu": isSticky })}>
                    {user && <div className={classNames("CalcalistMobileNavigationMenuItem CalcalistMobileNavigationMenuItemLoggedUser")}>
                        <span className="CalcalistMobileNavigationMenuItemText">שלום</span>  <span className="CalcalistMobileNavigationMenuItemTextUser">{user.firstName}</span>
                    </div>}
                    {user && !isUserSubscribed && <div className={classNames("CalcalistMobileNavigationMenuItem", "subscribed-item")}>
                        <span className="CalcalistMobileNavigationMenuItemSubscribeText">רכישת מנוי</span>
                        <div className="CalcalistSubscribeIcon"></div>
                    </div>}
                    {!user && <div onClick={this.loginUser} className="CalcalistMobileNavigationMenuItem">
                        <span className="CalcalistMobileNavigationMenuItemText">התחברות</span>
                        <div className="CalcalistUserConenctIcon"></div>
                    </div>}
                    {user && <a href="https://qa.calcalist.co.il/category/31602" onClick={()=>this.handleMenuItem("ניהול חשבון")} className={classNames("CalcalistMobileNavigationMenuItem")}>
                        <span className="CalcalistMobileNavigationMenuItemText">ניהול חשבון</span>
                        <div className="CalcalistUserManageIcon"></div>
                   </a>}
                    {!!menuItems.length && menuItems.map((item) => {
                        return (
                            <div key={item.id} className="CalcalistMobileNavigationMenuItem"  onClick={()=>this.handleMenuItem(item.name)}>
                                <HyperLinkBuilder className="CalcalistMobileNavigationMenuItemText" href={item.link} linkTarget={item.targetLink}>
                                    {item.name}
                                </HyperLinkBuilder>
                                <SiteSimpleImage src={item.url} className="icon-container" />
                            </div>
                        )
                    })}
                    {user && <div onClick={this.discconectUser} className="CalcalistMobileNavigationMenuItem">
                        <span className="CalcalistMobileNavigationMenuItemText">התנתקות</span>
                        <div className="CalcalistUserDisconenctIcon"></div>
                    </div>}
                    <div onClick={this.toggleUserMenu} className="CalcalistMobileNavigationMenuItem">
                        <div className="closeArrowIcon" />
                    </div>
                </div>
                <div className={classNames({ "CalcalistMobileNavigationBlurScreen": isMenuVisible })}></div>
            </div>
        )
    }
}

