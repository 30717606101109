require("./ctech_calcalist_site_calcalistComponentaShareMenu.less");
require("./ctech_site_calcalistComponentaShareMenu-mobile.less");

import {
    openFacebookShare,
    openTwitterShare,
    openMailShare,
    analyticsPushFunc,
    openLinkedinShare,
    openWhatsAppShare,
} from "../../widgetsUtils";
import { isWcmEditor } from "config/serverConfig";
import {
    SiteSettings,
    SiteSettingsHOC,
} from "../../../global/components/siteSettingsHOC";

interface CalcalistComponentaShareMenuState {
    isButtonsVisible: boolean;
}
interface shareMenuProps {
    id?: string;
    url: string;
    title?: string;
    isLTR?: boolean;
    isArticle?: boolean;
    showOnHover?: boolean;
    showWA?: boolean;
    isResponsive?: boolean;
}

export class CalcalistShareMenu extends React.Component<
    shareMenuProps,
    CalcalistComponentaShareMenuState
> {
    public static siteScriptName = "CalcalistShareMenu";
    constructor(props: shareMenuProps) {
        super(props);
        this.state = {
            isButtonsVisible: false,
        };
    }
    private openShare = async () => {
        const { title, id, url } = this.props;
        const shareData = { url: url + "#" + id, title: title };
        try {
            // @ts-ignore
            await navigator.share(shareData);
        } catch (err) {
            console.log("error with sharing function", err);
        }
    };

    private toggleMenu = (value?: boolean) => {
        let isButtonsVisible;
        if (value == undefined || value == false) {
            isButtonsVisible = "fadeIn";
        } else if (value == true) {
            isButtonsVisible = "";
        }
        isButtonsVisible = value;
        this.setState({
            isButtonsVisible,
        });
    };

    render() {
        const { isButtonsVisible } = this.state;
        const {
            url,
            title,
            isLTR,
            isArticle,
            showOnHover,
            showWA,
            id,
            isResponsive,
        } = this.props;
        const isVisible = isButtonsVisible ? "fadeIn" : "";
        const isApp =
            typeof window !== "undefined" &&
            window.location.href.indexOf("IsApp") != -1;
        return (
            <div className="ShareMenu">
                <div
                    className={`shareBtnsBox ${isVisible}`}
                    onMouseEnter={
                        showOnHover && !isResponsive
                            ? () => this.toggleMenu(true)
                            : null
                    }
                    onMouseLeave={
                        showOnHover && !isResponsive
                            ? () => this.toggleMenu(false)
                            : null
                    }
                >
                    <button
                        onClick={() =>
                            !isWcmEditor()
                                ? openFacebookShare(
                                      url,
                                      title,
                                      () =>
                                          analyticsPushFunc(
                                              "Facebook",
                                              "Article Main Image",
                                              title
                                          ),
                                      "Article Main Image"
                                  )
                                : null
                        }
                        className="calcalistShare fb"
                    />
                    <button
                        onClick={() =>
                            !isWcmEditor()
                                ? openTwitterShare(
                                      url,
                                      () =>
                                          analyticsPushFunc(
                                              "Twitter",
                                              "Article Main Image",
                                              title
                                          ),
                                      "Article Main Image"
                                  )
                                : null
                        }
                        className="calcalistShare tw"
                    />
                    {isArticle && (
                        <button
                            onClick={() =>
                                !isWcmEditor()
                                    ? openMailShare(
                                          title,
                                          "כלכליסט",
                                          url,
                                          () =>
                                              analyticsPushFunc(
                                                  "Mail",
                                                  "Article Main Image",
                                                  title
                                              ),
                                          "Article Main Image"
                                      )
                                    : null
                            }
                            className="calcalistShare mail"
                        />
                    )}
                    {isLTR && (
                        <button
                            onClick={() =>
                                !isWcmEditor()
                                    ? openLinkedinShare(url, () =>
                                          analyticsPushFunc(
                                              "Linkedin",
                                              "Article Main Image",
                                              title
                                          )
                                      )
                                    : null
                            }
                            className="calcalistShare li"
                        />
                    )}
                    {(isLTR || showWA) && (
                        <button
                            onClick={() =>
                                !isWcmEditor()
                                    ? openWhatsAppShare(
                                          url,
                                          () =>
                                              analyticsPushFunc(
                                                  "Mail",
                                                  "Article Main Image",
                                                  title
                                              ),
                                          "Article Main Image"
                                      )
                                    : null
                            }
                            className="calcalistShare wa"
                        />
                    )}
                </div>
                {isApp && isResponsive ? (
                    <a
                        href={`share_article/${id}`}
                        title={"share"}
                        className="toggleShareButtons"
                    ></a>
                ) : (
                    <button
                        className={`toggleShareButtons ${isVisible} ${
                            isResponsive ? "mobileShareBtn" : ""
                        }`}
                        onClick={!isResponsive ? () => this.toggleMenu() : null}
                        onMouseEnter={
                            showOnHover && !isResponsive
                                ? () => this.toggleMenu(true)
                                : null
                        }
                        onMouseLeave={
                            showOnHover && !isResponsive
                                ? () => this.toggleMenu(false)
                                : null
                        }
                    />
                )}
            </div>
        );
    }
}
