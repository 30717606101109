import { MainMobileHeaderItem, SecondaryMobileHeaderItem, MenuSettings } from "./categoryHeaderMutamWrapper";
import { HyperLinkBuilder, SimpleLinkRenderer } from "../../../../../commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "../../../../../commonComponents/inline/siteSimpleImage";


interface categoryHeaderMutamSideMenuProps {
    mainItems: MainMobileHeaderItem[]
    secondaryItems: SecondaryMobileHeaderItem[]
    showSideMenu: boolean
    menuSettings: MenuSettings
    domain:string
    oncloseMenu:()=>void
}
interface menuPopUpState {
 
    userInput: string
}
export class CategoryHeaderMutamSideMenu extends React.Component<categoryHeaderMutamSideMenuProps, menuPopUpState>{
    constructor(props) {
        super(props)

        this.state = {
            userInput: "",
        }

    }
    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }
    private clickAcessibilityBtn = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    private search = () => {
        const { userInput: typedUserInput } = this.state;

        if (!typedUserInput) {
            return
        }
        let fullUrl = ""
        const urlTail = "?cx=partner-pub-0047680307646695:9309925491&cof=FORID:10&ie=UTF-8&q="
        fullUrl = `${this.props.domain}/category/3735${urlTail}${typedUserInput}`

        fullUrl
        window.open(fullUrl, '_top');
    }
    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }

    render() {
        const { mainItems, secondaryItems, showSideMenu, menuSettings, oncloseMenu } = this.props
        const {userInput}=this.state

        const termsLinkTarget = menuSettings.termsLinkTarget.linkOpenType === "new" ? "_blank" : "_top";
        const privacyLinkTarget = menuSettings.privacyLinkTarget.linkOpenType === "new" ? "_blank" : "_top";
        return (
            <div className={`category-header-mutam-side-menu ${showSideMenu ? "show" : "hide"}`}>
                <div className="searchArea">
                    <button className="srhBtn" onClick={this.search} />
                    <input
                        alt="חפש/י"
                        className="mutamSrchBox"
                        id="mutamSrchBox"
                        aria-label="חיפוש"
                        type="text"
                        value={userInput}
                        placeholder="חפש/י"
                        onChange={this.updateInputValue}
                        onKeyPress={this.handleInputKeyPress}

                    />
                </div>
                <div className="navArea">
                <div className="header-categories-list">
              

                    <div className="header-items main-items">
                        {mainItems.map(item =>
                            <SimpleLinkRenderer href={item.link} target={item.linkTarget} ariaLabel={item.title} className="header-item main-item" key={item.itemId}>
                                {item.sideMenuImage && <SiteSimpleImage src={item.sideMenuImage} alt={item.title} title={item.title} width={15} height={15} />}
                                <div className="item-title">{item.title}</div>
                            </SimpleLinkRenderer>
                        )}
                    </div>

                    <div className="separator"></div>

                    <div className="header-items secondary-items">
                        {secondaryItems.map(item =>
                            <SimpleLinkRenderer href={item.link} target={item.linkTarget} ariaLabel={item.title} className="header-item secondary-item" key={item.itemId}>
                                <div className="item-title">{item.title}</div>
                            </SimpleLinkRenderer>
                        )}
                    </div>

                    <div className="header-items menu-settings">
                        <SimpleLinkRenderer href={menuSettings.privacyLink.link.publishedLink} target={termsLinkTarget} ariaLabel={'מדיניות פרטיות'} className="menu-settings-item">
                            <div className="menu-settings-title">מדיניות פרטיות</div>
                        </SimpleLinkRenderer>
                        <SimpleLinkRenderer href={menuSettings.termsLink.link.publishedLink} target={privacyLinkTarget} ariaLabel={'תנאי שימוש'} className="menu-settings-item">
                            <div className="menu-settings-title">תנאי שימוש</div>
                        </SimpleLinkRenderer>
                    </div>

                </div>

                <div className="side-menu-black-menu">
                    <SimpleLinkRenderer href="/stock" className="black-menu-link" title="פורטל פיננסי">
                        <div className="menu-icon finance-icon" />
                        <span className="menu-title">פורטל פיננסי</span>
                    </SimpleLinkRenderer>

                    <SimpleLinkRenderer href="/tv" className="black-menu-link" title="TV כלכליסט">
                        <div className="menu-icon tv-icon" />
                        <span className="menu-title">TV כלכליסט</span>
                    </SimpleLinkRenderer>

                    <SimpleLinkRenderer href="/podcast" className="black-menu-link" title="פודקאסט כלכליסט">
                        <div className="menu-icon podcast-icon" />
                        <span className="menu-title">פודקאסט כלכליסט</span>
                    </SimpleLinkRenderer>

                    <SimpleLinkRenderer href="/redmail" className="black-menu-link" title="דואר אדום">
                        <div className="menu-icon red-mail-icon" />
                        <span className="menu-title red-mail-title">דואר אדום</span>
                    </SimpleLinkRenderer>

                    <div id="INDmenu-btn3" className="black-menu-link" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button"
                        onClick={this.clickAcessibilityBtn}>
                        <div className="menu-icon accessability-icon" />
                        <span className="menu-title">נגישות</span>
                    </div>
                </div>
                </div>
             

               <div className="side-menu-mask" onClick={oncloseMenu}></div> 
            </div>
        )
    }
}
