import { ArticleImagePriceTagData } from "../data/articleImagePriceTagData"
import { isMobile } from "../../../../utils/isMobile";
require("./site_articleImagePriceTagPointsComponent.less")
var clickOutside = require("react-click-outside")
interface ArticleImagePriceTagPointsComponentProps {
    data: ArticleImagePriceTagData
    links: string[]
}

interface ArticleImagePriceTagPointsComponentState {
    selectedPoint: number
}
@clickOutside
export class ArticleImagePriceTagPointsComponent extends React.Component<ArticleImagePriceTagPointsComponentProps, ArticleImagePriceTagPointsComponentState>{
    public static siteScriptName = "ArticleImagePRiceTagPointComponent";
    constructor(props) {
        super(props)
        this.state = {
            selectedPoint: -1
        }
    }

    handleIndexChange = (index: number) => {
        this.setState({ selectedPoint: index })
    }

    handleClickOutside = () => {
        this.setState({ selectedPoint: -1 })
    }

    getMobileXCordination = (precent: number) => {
        const { data: { imageId } } = this.props
        //width of .point-box
        const boxWidth = 266
        const baseConvert = 630
        const image = document.getElementById('ReduxEditableImage_' + imageId)
        if (image) {
            const boxSizeInPrecentage = this.roundedPrecentage(boxWidth, baseConvert)
            if (precent + boxSizeInPrecentage > 100) {
                return precent - ((precent + boxSizeInPrecentage / 2) - 100)
            }
            else if (precent - boxSizeInPrecentage < 0) {
                return precent + Math.abs((precent - boxSizeInPrecentage / 2))
            }
        }
        return precent
    }

    roundedPrecentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue
    }

    public render() {
        const { data: { tagPoints } } = this.props;
        let {data:{pointsColor}} = this.props;

        // const link = getUrl(linkProperties)

        if(!pointsColor){
            pointsColor = "#fff";
        }

        const pointOnPicture = _.map(tagPoints, (p, index) => {
            const { links } = this.props
            const { selectedPoint } = this.state
            const link = links[index]
            if (index === selectedPoint) {
                return (
                    <div key={index} className="point-box" style={{ left: `${isMobile ? this.getMobileXCordination(p.style.width) : p.style.width}%`, top: `${p.style.height}%` }}>
                        <div className="close-btn">
                            <span onClick={() => this.handleIndexChange(-1)}>X</span>
                        </div>
                        {!!p.price &&
                            <div className="price-row">
                                <span>{p.price && new Intl.NumberFormat().format(p.price)}</span>₪
                            </div>}
                        <div className="desc-row">
                            {`${p.description}`}
                        </div>
                        {p.additionalText &&
                            <div className="additional-row">
                                {`${p.additionalText}`}
                            </div>}
                        {link &&
                            <div className="buy-row">
                                <a className="buy-btn" href={link} target={p.linkTargetProperties.linkOpenType === "new" ? "_blank" : null}>
                                    {`${!p.insideDotBtnText ? "לרכישה" : p.insideDotBtnText} >`}
                                </a>
                            </div>}
                    </div>
                )
            }
            return (
                <div key={index} className="pont-dot-parent" style={{ "--pointX": `${p.style.width}%`, "--pointY": `${p.style.height}%` }}>
                    <div
                        className={"point-dot"}
                        style={{ "--pointColor": p.dotColor ? p.dotColor : pointsColor  }}
                        key={index}
                        onClick={() => this.handleIndexChange(index)}
                    >
                        <span className="dot-plus" style={{ "--plusColor": p.plusColor ? p.plusColor : "#000" }}>+</span>
                        <div className="dot-text">
                            {`${!p.dotText ? "לצפייה במוצר" : p.dotText} >`}
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <>
                {pointOnPicture}
            </>
        )
    }
}
