
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { toggleSpotImPopUp } from "../../../article/componentas/articleSocialShare1280Component/articleSocialShareNew1280";
import { MainNavMutam } from "../../../ynet1280/ynetHeader/components/mutamComponents/mainNavMutam";
import { HeaderScrolledMenu } from "./headerScrolledMenu";

interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isFontMenuOpen: boolean

}
interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    flashPageLink: string
    flashLinkTarget: LinkTargetProperties
    redMailLink: string
    mailLinkTarget: LinkTargetProperties
    radioLink:string 
    radioLinkTarget:LinkTargetProperties
    logoData: Logo
    ynetSearchPageLink: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    linkToInst:string
    isArticle: boolean
    layout?: string
    domain: string
    followUsText:string
    shoppingPageUrl: string
    shoppingPageUrlTarget: LinkTargetProperties
    linkToRadioNas?:string 
    linkToTiktok?:string
}
export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}
export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}


export class SiteMutamRadionasHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteMutamRadionasHeaderComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isFontMenuOpen: false
        }
    }
    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened })
        document.body.classList.toggle('lock-scroll');
    }
    //

    private stickyBar() {
        const headerPos = document.querySelector(".radionas-header").getBoundingClientRect().top
        if (headerPos + 40 < 0) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }

    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
    }
  



    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }
    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private openShare = async () => {
        const { domain } = this.props;
        const current_url = `${window.location.href}?utm_source=${domain}&utm_medium=social&utm_campaign=general_share`;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }
    private handleCommentsPopupVisability = () => {
        if (typeof window !== "undefined") {
            if (window.enableSpotIm) {
                const commentsPopUp = document.querySelector('.spotimWrapper');
                if (commentsPopUp) {
                    toggleSpotImPopUp(commentsPopUp)
                }
            }
            else {
                window.handleCommentsPopupVisability && window.handleCommentsPopupVisability();
            }
        }
    }



    private clickAcessibilityBtn = () => {
        window[`EquallyAI`]  &&  window[`EquallyAI`].openToolbox();
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    
    render() {
        const { mailLinkTarget, flashPageLink, flashLinkTarget,radioLinkTarget,followUsText,   mainNavItems, groupsWithItems,linkToInst, logoData, 
            ynetSearchPageLink, linkToFb, linkToTw, linkToYtube,  redMailLink, layout, domain,radioLink , 
            linkToTiktok, linkToRadioNas, shoppingPageUrl, shoppingPageUrlTarget } = this.props
        const { isSticky,  isOpened } = this.state
        const numOfComment = typeof window !== "undefined" && window.handleCommentsPopupVisability && window.wcmNumOfComments && window.wcmNumOfComments;
        const hidden = isSticky ? "hidden" : ""
     
        

            return (
                <div className={classNames("RadionasMutamHeader")} id="RadionasMutamHeader" >

                    <div className={`logo`}>
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                        <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>

                        <button className={`menuHamburger ${isOpened ? "closeX" : ""}`} onClick={this.openMenu} />
                    </div>


                    <MainNavMutam
                        mainNavItems={mainNavItems}
                        openHam={this.openMenu}
                        className={hidden}
                        hideNav={true}
                    >
                             {shoppingPageUrl && shoppingPageUrl.length>0 &&  <HyperLinkBuilder href={shoppingPageUrl} linkTarget={shoppingPageUrlTarget} title={"ناسكم"} >
                            <span className="headerIcon shopping" />
                        </HyperLinkBuilder>}
                      {radioLink && radioLink.length>0 && <HyperLinkBuilder href={radioLink} linkTarget={radioLinkTarget}  title={"راديو الناس"}>
                            <span className="headerIcon radio" />
                        </HyperLinkBuilder>}
                    </MainNavMutam>


           
                    <HeaderScrolledMenu
                        groupsWithItems={groupsWithItems}
                        isVisible={this.state.isOpened}
                        onClose={this.openMenu}
                        ynetSearchPageLink={ynetSearchPageLink}
                        linkToFb={linkToFb}
                        linkToTw={linkToTw}
                        linkToYtube={linkToYtube}  
                        layout={layout}
                        domain={domain}
                        flashPageLink={flashPageLink}
                        flashLinkTarget={flashLinkTarget}
                        redMailLink={redMailLink}
                        mailLinkTarget={mailLinkTarget}
                        linkToInst={linkToInst}
                        followUsText={followUsText}
                        linkToRadioNas={linkToRadioNas}
                        linkToTiktok={linkToTiktok}
                        />
                </div>

            )

    }
}