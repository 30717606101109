import { LiveVideoPlayer } from "./liveVideoPlayer";

interface LiveVideoPlayerMutamComponentaProps {
    showVideoOnMobile: boolean
    videoSource: string
    lang?: string
}
export class LiveVideoPlayerMutamComponentaEng extends React.Component<LiveVideoPlayerMutamComponentaProps, {}> {
    static siteScriptName = "liveVideoPlayerEng"
    constructor() {
        super();
    }

    render() {
        const { showVideoOnMobile,videoSource } = this.props;
        
        if (!showVideoOnMobile || !videoSource) return null;

        return (
            <div className="liveVideoPlayerMutamComponenta">
                <LiveVideoPlayer
                    lang={this.props.lang} 
                    videoSource={videoSource}
                    isCustomControllers={true} />
            </div>
        )
    }
}